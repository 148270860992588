import React, { useState, useRef, useEffect } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import Navbartop from "./navbar";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import Table from "react-bootstrap/Table";
import Moment from "moment";
import { Link } from "react-router-dom";
import { getReportsList } from "../store/slices/activity";
import ShowSkeleton from "../utils/helpers/Skeleton";
import dog from "../Assets/Images/corgi.png";

function ReportsList() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totaldata, settotalData] = useState();
  const dispatch = useDispatch();
  const [roles, setRole] = useState("cook");
  const [isLoading, setisLoading] = useState(true);
  const [item, setItem] = useState({
    role: roles,
    limit: 25,
    page: 1,
  });

  const itemsPerPage = 25;
  const pagesToShowInitially = 2;

  const handleApplyFilter = () => {
    const item = {
      role: roles,
      limit: 25,
      page: 1,
    };

    setItem(item);
    setCurrentPage(1);
    getReports(item);
  };

  const handlePageClick = ({ selected }) => {
    const newPage = selected + 1;
    setItem({
      ...item,
      limit: 25,
      page: newPage,
    });
    setCurrentPage(newPage);
  };

  const handlenameChange = (e) => {
    setRole(e.target.value);
  };

  React.useEffect(() => {
    getReports(item);
  }, [dispatch, currentPage]);

  useEffect(() => {
    document.title = "CookandChef Admin | Employer Report List";
  }, []);

  const getReports = (item) => {
    dispatch(getReportsList(item))
      .unwrap()
      .then((data) => {
        setData(data.activity.data);
        settotalData(data.activity.totalDataCount);
        setTimeout(() => {
          setisLoading(false);
        }, [1000]);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  let userdata = JSON.parse(localStorage.getItem("users"));
  let role = userdata.data.roleId.roleName;
  return (
    <>
      <Navbartop />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div
                    className="text-start my-2 ms-1"
                    style={{
                      fontSize: "2.2dvh",
                      fontWeight: "600",
                      color: "#ff5c09",
                    }}
                  >
                    <Link to="/home">Home</Link> &#8811;&nbsp;Employer Report
                    List
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Employer Report List</h4>
                      </div>
                    </div>

                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        {isLoading ? (
                          <Col lg={12} xs={12} md={12} align="center">
                            {ShowSkeleton()}
                          </Col>
                        ) : data?.length === 0 ? (
                          <Col lg={12} md={12} sm={12} xs={12}>
                            <Row
                              className="d-flex justify-items-center align-items-center"
                              style={{
                                backgroundColor: "white",
                                // height: "20dvh",
                                // width: "100%",
                                borderRadius: "15px",
                                padding: "20px",
                              }}
                            >
                              <Col lg={12} align="center">
                                <p style={{ fontSize: "18px" }}>
                                  <b>No data</b> found!
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        ) : (
                          <Table responsive hover>
                            <thead>
                              <tr>
                                <th className="text-left">Cook Name</th>
                                <th className="text-left">Job/ Event Name</th>
                                <th className="text-left">Reason</th>
                                <th className="text-left">Comment</th>
                                <th className="text-left">Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.length > 0 &&
                                data?.map((repostList) => (
                                  <tr key={repostList?._id}>
                                    <td className="text-left">
                                      <Link
                                        to={`/cookdetails/${repostList?.cookId?.id}`}
                                        target="_blank"
                                        style={{
                                          color: "#a020f0",
                                        }}
                                      >
                                        {repostList?.cookId?.fullName?.substring(
                                          0,
                                          30
                                        )}
                                      </Link>
                                    </td>
                                    <td className="text-left">
                                      {repostList?.jobId && (
                                        <Link
                                          to={`/JobsDetails/${repostList?.jobId?.id}`}
                                          target="_blank"
                                          style={{
                                            color: "#a020f0",
                                          }}
                                        >
                                          {repostList?.jobId?.designation?.substring(
                                            0,
                                            25
                                          )}
                                        </Link>
                                      )}
                                      {repostList?.requirementId && (
                                        <Link
                                          to={`/clientdetails/${repostList?.requirementId?.clientId}`}
                                          target="_blank"
                                          style={{
                                            color: "#a020f0",
                                          }}
                                        >
                                          {repostList?.requirementId?.clientId?.substring(
                                            0,
                                            25
                                          )}
                                        </Link>
                                      )}
                                      {repostList?.eventId && (
                                        <Link
                                          to={`/eventDetails/${repostList?.eventId?.id}`}
                                          target="_blank"
                                          style={{
                                            color: "#a020f0",
                                          }}
                                        >
                                          {repostList?.eventId?.clientId?.substring(
                                            0,
                                            25
                                          )}
                                        </Link>
                                      )}
                                    </td>

                                    <td className="text-left">
                                      <p>{repostList?.reason}</p>
                                    </td>
                                    <td className="text-left">
                                      <p>{repostList?.comment}</p>
                                    </td>
                                    <td className="text-left">
                                      {Moment(repostList?.createdAt).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                        )}
                      </div>
                      <div className="justify-content-right align-right">
                        {totaldata / itemsPerPage > 1 && (
                          <ReactPaginate
                            previousLabel="<"
                            nextLabel=">"
                            breakLabel="..."
                            breakLinkClassName={"page-link"}
                            pageCount={totaldata / itemsPerPage}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={pagesToShowInitially}
                            onPageChange={handlePageClick}
                            containerClassName="pagination"
                            activeClassName="active"
                            pageLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            disabledClassName="disabled"
                            initialPage={currentPage - 1}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReportsList;
