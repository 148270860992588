import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "../../services/auth.service";
import PlanService from './../../services/plan.service';


export const addEmployerPlan = createAsyncThunk(
  "plan/post/employerplan",
  async (item, thunkAPI) => {
    try {
      const data = await PlanService.postEmployerPlan(item);
      return { users: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const addClientPlan = createAsyncThunk(
  "plan/post/clientplan",
  async (item, thunkAPI) => {
    try {
      const data = await PlanService.postClientPlan(item);
      return { users: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const getCookPlan = createAsyncThunk(
  "plan/get/cookplan",
  async (thunkAPI) => {
    try {
      const data = await PlanService.getCookPlan();
      return { users: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);
export const addCookPlan = createAsyncThunk(
  "plan/post/cookplan",
  async (item, thunkAPI) => {
    try {
      const data = await PlanService.postCookPlan(item);
      return { users: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const getClientPlan = createAsyncThunk(
  "plan/get/clientplan",
  async (thunkAPI) => {
    try {
      const data = await PlanService.getClientPlan();
      return { users: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const getSingleClientPlan = createAsyncThunk(
  "plan/get/clientplan",
  async (id,thunkAPI) => {
    try {
      const data = await PlanService.getSingleClientPlan(id);
      return { users: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);


export const DeleteClientPlan = createAsyncThunk(
  "plan/delete/clientPlan",
  async(id,thunkAPI) => {
      try {
          const data = await PlanService.deleteClientPlan(id);
          return { user: data };
      } catch (error) {
          const message =
              (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
              error.message ||
              error.toString();
          return thunkAPI.rejectWithValue({ message });
      }
  }
);


export const DeleteCookPlan = createAsyncThunk(
  "plan/delete/cookPlan",
  async(id,thunkAPI) => {
      try {
          const data = await PlanService.deleteCookPlan(id);
          return { user: data };
      } catch (error) {
          const message =
              (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
              error.message ||
              error.toString();
          return thunkAPI.rejectWithValue({ message });
      }
  }
);


export const DeleteEmployerPlan = createAsyncThunk(
  "plan/delete/clientPlan",
  async(id,thunkAPI) => {
      try {
          const data = await PlanService.deleteEmployerPlan(id);
          return { user: data };
      } catch (error) {
          const message =
              (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
              error.message ||
              error.toString();
          return thunkAPI.rejectWithValue({ message });
      }
  }
);


export const getEmployerPlan = createAsyncThunk(
  "plan/get/clientplan",
  async (id,thunkAPI) => {
    try {
      const data = await PlanService.getEmployerPlan(id);
      return { users: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);


export const getAllEmployerPlan = createAsyncThunk(
  "plan/get/clientplan",
  async (thunkAPI) => {
    try {
      const data = await PlanService.getAllEmployerPlan();
      return { users: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);


export const editEmployerPlan = createAsyncThunk(
  "plan/edit/employerplan",
  async (item,thunkAPI) => {
    try {
      const data = await PlanService.editEmployerPlan(item);
      return { users: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);


export const editClientPlan = createAsyncThunk(
  "plan/edit/clientplan",
  async (item,thunkAPI) => {
    try {
      const data = await PlanService.editClientPlan(item);
      return { users: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

export const getSingleCookPlan = createAsyncThunk(
  "plan/get/cookplan",
  async (id,thunkAPI) => {
    try {
      const data = await PlanService.getSingleCookPlan(id);
      return { users: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);


export const editCookPlan = createAsyncThunk(
  "plan/edit/cookplan",
  async (item,thunkAPI) => {
    try {
      const data = await PlanService.editCookPlan(item);
      return { users: data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);


const initialState = {
  loading: false,
  error: "",
  users: AuthService.getusersDetails() || null,
  isLoggedIn: false,
};

const planSlice = createSlice({
  name: "plan",
  initialState,
  extraReducers: {
    [addEmployerPlan.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.users = null;
    },
    [addEmployerPlan.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.users = action.payload.users;
    },
    [addEmployerPlan.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.users = null;
    },
    [addClientPlan.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.users = null;
    },
    [addClientPlan.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.users = action.payload.users;
    },
    [addClientPlan.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.users = null;
    },
    [addCookPlan.pending]: (state) => {
      state.loading = true;
      state.error = "";
      state.isLoggedIn = false;
      state.users = null;
    },
    [addCookPlan.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.isLoggedIn = true;
      state.users = action.payload.users;
    },
    [addCookPlan.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
      state.isLoggedIn = false;
      state.users = null;
    },
  },
});


const { reducer } = planSlice;
export default reducer;