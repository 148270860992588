import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useDispatch } from "react-redux";
import { getCookDetails } from "../store/slices/cook";
import { editCateringDetails } from "../store/slices/employer";
import { getCuisines } from "../store/slices/config";
import Navbartop from "./navbar";

function EditCatering() {
  const { id } = useParams();
  const [cuisines, setCuisines] = useState([]);
  const [chefCuisines, setchefCuisines] = useState([]);
  const [foodType, setFoodType] = useState("");
  const [minPlates, setMinPlates] = useState("");
  const [fssai, setFssai] = useState("");
  const [website, setWebsite] = useState("");
  const [teamSize, setTeamSize] = useState("");
  const [errors, setErrors] = useState({});
  const [isdisabled, setisDisabled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isValidWebsite = (website) => {
    return /^www\.[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/.test(website);
  };

  const isValidFSSAI = (value) => /^[12].{13}$/.test(value);

  const isValidTeamSize = (value) => {
    const intValue = parseInt(value, 10);
    return intValue >= 3 && intValue <= 100000 && /^[1-9]\d*$/.test(value);
  };

  useEffect(() => {
    document.title = "CookandChef Admin | Edit Catering";
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    const validationErrors = {};
    if (!chefCuisines?.length) {
      validationErrors.cuisines = "Please enter cuisines known";
    }

    if (!foodType) {
      validationErrors.foodType = "Please select food type";
    }
    if (!minPlates) {
      validationErrors.minPlates = "Please select minimum number of plates";
    }
    if (website && !isValidWebsite(website)) {
      validationErrors.website = "Please enter a valid website";
    }
    if (fssai && !isValidFSSAI(fssai)) {
      validationErrors.fssai = "Please enter a valid FSSAI number";
    }
    if (teamSize && !isValidTeamSize(teamSize)) {
      validationErrors.teamSize = "Team size must be min 3";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    } else {
      let item = {
        id: id,
        fssai: fssai,
        cateringFoodType: parseInt(foodType) || undefined,
        cateringMinPlates: parseInt(minPlates) || undefined,
        cateringCuisines: chefCuisines?.length ? chefCuisines : undefined,
        teamSize: parseInt(teamSize) || 3,
        website: website,
      };
      dispatch(editCateringDetails(item))
        .unwrap()
        .then((data) => {
          alert(data?.users?.message);
          navigate(-1);
        })
        .catch(({ message }) => {
          alert(message);
        });
    }
  };

  const handleChange = (e) => {
    const validationErrors = { ...errors };
    const { name } = e.target;
    let value = e.target.value;
    switch (name) {
      case "foodType":
        setFoodType(value);
        validationErrors[name] = value === "" ? "Please select food type" : "";
        if (value !== "") {
          validationErrors[name] = "";
        }
        break;
      case "minPlates":
        setMinPlates(value);
        validationErrors[name] =
          value === "" ? "Please select minimum plates" : "";
        if (value !== "") {
          validationErrors[name] = "";
        }
        break;
      case "fssai":
        setFssai(value);
        validationErrors[name] =
          value !== "" && !isValidFSSAI(value)
            ? "Please enter FSSAI number"
            : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;
      case "website":
        setWebsite(value);
        validationErrors[name] =
          value !== "" && !isValidWebsite(value)
            ? "Please enter valid website"
            : "";
        if (!value === "" && isValidWebsite(value)) {
          delete validationErrors[name];
        }
        break;
      case "teamSize":
        value = value.replace(/\D/g, "");

        // Limit the length to 10 characters
        if (value.length > 3) {
          value = value.slice(0, 3);
        }
        setTeamSize(value);
        validationErrors[name] =
          value !== "" && !isValidTeamSize(value)
            ? "Team size must be min 3"
            : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;
      default:
        break;
    }
    setErrors(validationErrors);
  };

  React.useEffect(() => {
    dispatch(getCuisines())
      .unwrap()
      .then((data) => {
        setCuisines(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  var options = cuisines?.map(function (item) {
    return item["cuisineName"];
  });

  React.useEffect(() => {
    dispatch(getCookDetails(id))
      .unwrap()
      .then((data) => {
        setchefCuisines(data.users.data.cateringCuisines);
        setMinPlates(data.users.data.cateringMinPlates);
        setFoodType(data.users.data.cateringFoodType);
        setTeamSize(data.users.data.teamSize);
        setWebsite(data.users.data.website);
        setFssai(data.users.data.fssai);
      })
      .catch(({ message }) => {
        //alert(message);
      });
  }, [dispatch]);

  const handleChefCuisineChange = (selected) => {
    const validationErrors = { ...errors };
    setchefCuisines(selected);
    validationErrors.cuisines =
      selected?.length === 0 ? "Please enter cuisines known" : "";
    if (!selected?.length === 0) {
      delete validationErrors.cuisines;
    }

    setErrors(validationErrors);
  };

  const cancelHandler = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <>
      <div>
        <Navbartop />

        <div className="container-fluid pb-5 ms-2 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-lg-10 col-md-12 col-12"></div>
                  <div
                    className="text-start my-1 ms-1"
                    style={{
                      fontSize: "2.2dvh",
                      fontWeight: "600",
                      color: "#ff5c09",
                    }}
                  >
                    <Link to="/cook/dashboard">Home</Link> &#8811;
                    <Link onClick={cancelHandler}> Cook Profile</Link> &#8811;
                    Edit Catering Profile
                  </div>
                  <Form autoComplete="off" noValidate onSubmit={submitHandler}>
                    <Row className="mt-4 gx-3 ml-2 d-flex justify-content-center ">
                      <Col lg={12} xs={11} className="mb-4">
                        <Row className="=d-flex justify=-content-center align-items-center">
                          <div
                            style={{
                              height: "80%",
                              width: "80%",
                              backgroundColor: "white",
                              borderRadius: "15px",
                              padding: "20px 40px",
                            }}
                          >
                            <Col lg={12}>
                              <Row>
                                <Col lg={6} className="p-3">
                                  <Form.Group className="my-2">
                                    <label className="mb-2 label">
                                      Cuisines Known *
                                    </label>
                                    <Typeahead
                                      clearButton
                                      id="basic-typeahead-multiple"
                                      labelKey="name"
                                      multiple
                                      className="input1"
                                      onChange={handleChefCuisineChange}
                                      options={options}
                                      placeholder="Cuisines known"
                                      selected={chefCuisines}
                                      isInvalid={!!errors.cuisines}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.cuisines}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Col>

                                <Col lg={6} className="p-3">
                                  <Form.Group className="my-2">
                                    <label className="mb-2 label">
                                      Food Type *
                                    </label>
                                    <Form.Control
                                      as="select"
                                      name="foodType"
                                      className="input1"
                                      value={foodType}
                                      onChange={handleChange}
                                      isInvalid={!!errors.foodType}
                                      isValid={
                                        !errors.foodType && foodType?.length > 0
                                      }
                                    >
                                      <option value="">Select</option>
                                      <option value="1">Pure Veg</option>
                                      <option value="2">Veg/Non-Veg</option>
                                      <option value="3">Jain Food</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.foodType}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Col>

                                <Col lg={6} className="p-3">
                                  <Form.Group className="my-2">
                                    <label className="mb-2 label">
                                      Min. Plates *
                                    </label>
                                    <Form.Control
                                      as="select"
                                      name="minPlates"
                                      className="input1"
                                      value={minPlates}
                                      onChange={handleChange}
                                      isInvalid={!!errors.minPlates}
                                      isValid={
                                        !errors.minPlates &&
                                        minPlates?.length > 0
                                      }
                                    >
                                      <option value="">Select</option>
                                      <option value="10">10</option>
                                      <option value="20">20</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                      <option value="250">250</option>
                                      <option value="500">500</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                      {errors.minPlates}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Col>
                                <Col lg={6} className="p-3">
                                  <Form.Group className="my-2">
                                    <label className="mb-2 label">FSSAI</label>
                                    <Form.Control
                                      name="fssai"
                                      value={fssai}
                                      onChange={handleChange}
                                      className="input1"
                                      isInvalid={!!errors?.fssai}
                                      isValid={
                                        !errors?.fssai && fssai?.length > 0
                                      }
                                      type="text"
                                      placeholder="Enter FSSAI number"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.fssai}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={12}>
                              <Row>
                                <Col lg={6} className="p-3">
                                  <Form.Group className="my-2">
                                    <label className="mb-2 label">
                                      Website
                                    </label>
                                    <Form.Control
                                      name="website"
                                      value={website}
                                      onChange={handleChange}
                                      isInvalid={!!errors.website}
                                      isValid={
                                        !errors.website && website?.length > 0
                                      }
                                      className="input1"
                                      type="text"
                                      placeholder="Enter website"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.website}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Col>
                                <Col lg={6} className="p-3">
                                  <Form.Group className="my-2">
                                    <label className="mb-2 label">
                                      Team Size
                                    </label>
                                    <Form.Control
                                      name="teamSize"
                                      value={teamSize}
                                      onChange={handleChange}
                                      isInvalid={!!errors.teamSize}
                                      isValid={
                                        !errors.teamSize && teamSize?.length > 0
                                      }
                                      className="input1"
                                      type="text"
                                      placeholder="Enter team size"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.teamSize}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Col>
                              </Row>
                            </Col>
                            <Row className="d-flex justify-content-center mt-4">
                              <Col lg={4} xs={8}>
                                <div className="d-flex justify-content-around my-2 align-items-center">
                                  <Button
                                    type="cancel"
                                    onClick={cancelHandler}
                                    className="cancelBtn"
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    disabled={isdisabled}
                                    type="submit"
                                    className="saveBtn ms-2"
                                  >
                                    Save
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditCatering;
