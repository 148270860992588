import React, { useEffect, useState } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import Navbartop from "./navbar";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { assignplan } from "../store/slices/config";
import { getAllEmployerPlan } from "../store/slices/plan";
import { UpgradeEmployerPlan } from "../store/slices/employer";

function UpgradeEmployerPlans() {
  const [datas, setDatas] = useState([]);
  const [isdisabled, setisDisabled] = useState(false);
  const [planId, setplanId] = useState();
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const employerdata = location.state?.employerdata || null;

  useEffect(() => {
    document.title = "CookandChef Admin | Upgrade Employer Plan";
  }, []);

  useEffect(() => {
    dispatch(getAllEmployerPlan(employerdata.employerId))
      .unwrap()
      .then((data) => {
        setDatas(data.users.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    const validationErrors = { ...errors };
    switch (name) {
      case "planId":
        setplanId(value);
        validationErrors[name] = value.trim === "" ? "Please select plan" : "";
        break;
      default:
        break;
    }
    setErrors(validationErrors);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate("");

  const submitHandler = (e) => {
    const validationErrors = {};
    e.preventDefault();
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    if (!planId) {
      validationErrors.planId = "Please select plan";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      let item = {
        transactionId: employerdata.planTransactionId.id,
        newPlanId: planId,
        employerId: employerdata.employerId,
      };

      dispatch(UpgradeEmployerPlan(item))
        .unwrap()
        .then(() => {
          alert("Plan upgraded successfully!");
          navigate(-1);
        })
        .catch(({ message }) => {
          alert(message);
        });
    }
  };

  const cancelHandler = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <>
      <Navbartop />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div
                    className="text-start my-2 ms-1"
                    style={{
                      fontSize: "2.2dvh",
                      fontWeight: "600",
                      color: "#ff5c09",
                    }}
                  >
                    <Link to="/home">Home</Link> &#8811;&nbsp;
                    <Link onClick={cancelHandler}>Employer Details</Link>{" "}
                    &#8811; Upgrade Plan
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Upgrade Plan</h4>
                      </div>
                    </div>
                    <section className="form-section">
                      <Form className="mx-4" onSubmit={submitHandler}>
                        <Row className="my-4  justify-content-start">
                          <Row className="d-flex ">
                            <Col lg={5} className="p-1">
                              <Form.Group>
                                <Form.Label>Plan *</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="planId"
                                  className="input1"
                                  value={datas.clientPlanName}
                                  onChange={handleChange}
                                  isInvalid={!!errors.planId}
                                >
                                  <option value="">Select</option>
                                  {datas.map((edudata, index) => (
                                    <option key={index} value={edudata.id}>
                                      {edudata.employerPlanName}
                                    </option>
                                  ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {errors.planId}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="justify-content-center mt-5">
                            <Col xs={6} md={4} lg={2} xl={2} xxl={2}>
                              <button
                                className="btn cancelBtn"
                                onClick={cancelHandler}
                              >
                                Cancel
                              </button>
                            </Col>{" "}
                            <Col xs={6} md={4} lg={2} xl={2} xxl={2}>
                              <button
                                className="btn saveBtn ms-2"
                                disabled={isdisabled}
                                type="submit"
                              >
                                Save
                              </button>
                            </Col>
                          </Row>
                        </Row>
                      </Form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpgradeEmployerPlans;
