import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, Link, NavLink, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Navbartop from "./navbar";
import { getCuisines } from "../store/slices/config";
import { EditChefDetails, getCookDetails } from "../store/slices/cook";
import { API_PATHS } from "../utils/constants/api.constants";

function EditChefProfile() {
  let user = JSON.parse(localStorage.getItem("user"));
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cuisines, setCuisines] = useState([]);
  const [chefCuisines, setchefCuisines] = useState([]);
  const [chefExperienceVal, setchefExperienceVal] = useState("");
  const [currentCompany, setCurrentCompany] = useState("");
  const [currentSalaryVal, setCurrentSalaryVal] = useState("");
  const [expectedSalaryVal, setExpectedSalaryVal] = useState("");
  const [skillsVal, setSkillsVal] = useState([]);
  const [skills, setSkills] = useState(true);
  const [currentCityName, setcurrentCityName] = useState("");
  const [relocate, setRelocate] = useState(0);
  const [jobSeeking, setjobSeeking] = useState(0);
  const [errors, setErrors] = useState({});
  const [resume, setResume] = useState("");
  const [isdisabled, setisDisabled] = useState(false);
  const [coordnates, setCoordinates] = useState([]);
  const [resumError, setResumeError] = useState();

  React.useEffect(() => {
    dispatch(getCookDetails(id))
      .unwrap()
      .then((data) => {
        setchefCuisines(data?.users?.data?.chefCuisines);
        setchefExperienceVal(data?.users?.data?.chefExperience);
        setCurrentCompany(data?.users?.data?.currentCompany);
        setCurrentSalaryVal(data?.users?.data?.currentSalary);
        setExpectedSalaryVal(data?.users?.data?.expectedSalary);
        setSkillsVal(data?.users?.data?.skills);
        setcurrentCityName(data?.users?.data?.currentCityName);
        setCoordinates(data?.users?.data?.currentCityCoordinates?.coordinates);
        setResume(data?.users?.data?.resume);
        setRelocate(
          data?.users?.data?.relocate === 0 ? 0 : data?.users?.data?.relocate
        );
        setjobSeeking(
          data?.users?.data?.jobSeeking === 0
            ? 0
            : data?.users?.data?.jobSeeking
        );
      })
      .catch(({ message }) => {});
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getCuisines())
      .unwrap()
      .then((data) => {
        setCuisines(data.user.data);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);
  var options = cuisines.map(function (item) {
    return item["cuisineName"];
  });

  useEffect(() => {
    document.title = "CookandChef Admin | Edit Chef";
  }, []);

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      const autoCompleteCity = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          componentRestrictions: { country: "in" },
        }
      );

      autoCompleteCity.addListener("place_changed", () => {
        const place = autoCompleteCity.getPlace();
        if (!place.geometry || !place.geometry.location) {
          setCoordinates([]);
          setErrors({
            ...errors,
            currentCityName:
              "Please select location from the suggestion drop-down",
          });
        } else {
          setErrors({
            ...errors,
            currentCityName: "",
          });

          setcurrentCityName(place.name);
          const coordinates = [
            place.geometry.location.lng(),
            place.geometry.location.lat(),
          ];
          setCoordinates(coordinates);
        }
      });
      inputRef.current.addEventListener("blur", () => {
        const place = autoCompleteCity.getPlace();

        const inputValue = inputRef.current.value.trim();
        if (inputValue && (!place?.geometry || !place?.geometry?.location)) {
          setCoordinates([]);
          setErrors({
            ...errors,
            currentCityName:
              "Please select location from the suggestion drop-down",
          });
        }
      });
    }
  }, [setcurrentCityName]);


  const handleChange = (e) => {
    const validationErrors = { ...errors };
    const { name } = e.target;
    let value = e.target.value;
    switch (name) {
      case "cuisine":
        setchefCuisines(value);
        validationErrors[name] = value === "" ? "Please enter cuisine" : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;

      case "chefExperienceVal":
        value = value.replace(/\D/g, "");

        // Limit the length to 10 characters
        if (value.length > 2) {
          value = value.slice(0, 2);
        }
        setchefExperienceVal(value);
        validationErrors[name] =
          value === "" || !isValidchefExperienceVal(value)
            ? "Max. experience allowed is 50 years"
            : "";
        if (!value === "" && isValidchefExperienceVal(value)) {
          delete validationErrors[name];
        }
        break;

      case "currentCompany":
        setCurrentCompany(value);
        validationErrors[name] =
          value !== "" && !isValidcurrentCompany(value)
            ? "Please enter a valid company name"
            : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;

      case "currentSalaryVal":
        value = value.replace(/\D/g, "");

        // Limit the length to 10 characters
        if (value.length > 7) {
          value = value.slice(0, 7);
        }
        setCurrentSalaryVal(value);
        validationErrors[name] =
          value === "" || !isValidcurrentSalary(value)
            ? "Salary must be greater than 5000, please enter 0 if you are a fresher"
            : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;

      case "expectedSalaryVal":
        setExpectedSalaryVal(value);
        value = value.replace(/\D/g, "");

        // Limit the length to 10 characters
        if (value.length > 7) {
          value = value.slice(0, 7);
        }
        validationErrors[name] =
          value === "" || !isValidexpectedSalary(value)
            ? "Salary must be greater than 5000"
            : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;

      case "skillsVal":
        if (value.trim() !== "") {
          setSkillsVal(value.split(","));
        } else {
          setSkillsVal([]);
          setSkills(false);
        }

        validationErrors[name] =
          value.trim() !== "" && !isValidskills(value)
            ? "Please enter a valid skills"
            : "";

        if (value !== "" && isValidskills(value)) {
          delete validationErrors[name];
        }
        break;

      case "currentCityName":
        setcurrentCityName(value);
        validationErrors[name] =
          value === "" && !isValidcurrentCity(value)
            ? "Please enter a valid city name"
            : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;
      default:
        break;
    }
    setErrors(validationErrors);
  };

  const isValidchefExperienceVal = (chefExperienceVal) => {
    return /^(0[1-9]|[1-4][0-9]?|50)$/.test(chefExperienceVal);
  };

  const isValidcurrentCompany = (currentCompany) => {
    return /^[a-zA-Z0-9\- '’,&@]{3,100}$/.test(currentCompany);
  };

  const isValidskills = (skillsVal) => {
    return /^[a-zA-Z0-9\- ',&@]{3,100}$/.test(skillsVal);
  };

  const isValidcurrentSalary = (currentSalaryVal) => {
    return /^(0|([5-9]\d{3,5})|([1-9]\d{4,5}))$/.test(currentSalaryVal);
  };

  const isValidexpectedSalary = (expectedSalaryVal) => {
    return /^(5000|1000000|[5-9][0-9]{3}|[1-9][0-9]{4,5})$/.test(
      expectedSalaryVal
    );
  };

  const isValidcurrentCity = (currentCityName) => {
    return /^[a-zA-Z0-9\- ',&@]{3,100}$/.test(currentCityName);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const validationErrors = {};
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    if (!chefCuisines?.length) {
      validationErrors.chefCuisines = "Please enter cuisines known";
    }
    if (!chefExperienceVal) {
      validationErrors.chefExperienceVal = "Please enter experience";
    }
    if (chefExperienceVal && !isValidchefExperienceVal(chefExperienceVal)) {
      validationErrors.chefExperienceVal =
        "Max. experience allowed is 50 years";
    }
    if (!isValidcurrentCity(currentCityName) || !coordnates?.length) {
      validationErrors.currentCityName = "Please enter valid city name";
    }

    if (currentCompany && !isValidcurrentCompany(currentCompany)) {
      validationErrors.currentCompany = "Please enter valid company name";
    }
    if (currentSalaryVal === "" || currentSalaryVal < 0) {
      validationErrors.currentSalaryVal = "Please enter current salary";
    }

    if (currentSalaryVal && !isValidcurrentSalary(currentSalaryVal)) {
      validationErrors.currentSalaryVal =
        "Salary must be greater than 5000, please enter 0 if chef is a fresher";
    }
    if (!isValidexpectedSalary(expectedSalaryVal)) {
      validationErrors.expectedSalaryVal = "Salary must be greater than 5000";
    }

    if (!expectedSalaryVal) {
      validationErrors.expectedSalaryVal = "Please enter expected salary";
    }

    if (!currentCityName) {
      validationErrors.currentCityName = "Please enter current city";
    }
    if (currentCompany && !isValidcurrentCompany(currentCompany)) {
      validationErrors.currentCompany = "Please enter current company";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    } else {
      const chefExperience = parseInt(chefExperienceVal);
      const currentSalary = parseInt(currentSalaryVal);
      const expectedSalary = parseInt(expectedSalaryVal);

      let item = {
        id: id,
        jobSeeking: jobSeeking === 0 ? 0 : jobSeeking === 1 ? 1 : undefined,
        chefCuisines: chefCuisines || undefined,
        skills: skillsVal || [],
        chefExperience: chefExperience || undefined,
        currentSalary: currentSalary || undefined,
        expectedSalary: expectedSalary || undefined,
        currentCompany: currentCompany || "",
        currentCityName: currentCityName || undefined,
        relocate: relocate === 0 ? 0 : relocate === 1 ? 1 : undefined,
        currentCityCoordinates: coordnates
          ? {
              type: "Point",
              coordinates: coordnates || [],
            }
          : undefined,
        resume: resume || undefined,
      };
      dispatch(EditChefDetails(item))
        .unwrap()
        .then((data) => {
          alert("Profile updated successfully");
          navigate(-1);
        })
        .catch(({ message }) => {
          alert(message);
        });
    }
  };

  const handleChefCuisineChange = (selected) => {
    const validationErrors = { ...errors };
    setchefCuisines(selected);
    validationErrors.chefCuisines =
      selected.length === 0 ? "Please enter cuisines known" : "";
    if (!selected.length === 0) {
      delete validationErrors.chefCuisines;
    }

    setErrors(validationErrors);
  };

  async function imageUpload(e) {
    setResumeError("");
    e.preventDefault();
    if (!e.target.files[0]) {
      setResumeError("Please select a file.");
      return;
    }

    // Read the file size
    const fileSize = e.target.files[0].size;
    // Check if the file size exceeds 2 MB
    if (fileSize > 2 * 1024 * 1024) {
      setResumeError("Please upload an image less than 2 MB.");
      return;
    }
    // Update the FormData with the resized Blob
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    var requestOptions = {
      method: "POST",
      body: formData,
      redirect: "follow",
      //   headers: { "x-access-token": token },
    };
    let response = await fetch(API_PATHS.uploadFile, requestOptions);
    let data = await response.json();
    setResume(data.data.location);
    // setMessage(data.message);
  }
  const cancelHandler = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <>
      <div>
        <Navbartop />

        <div className="container-fluid pb-5 ms-2 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-lg-10 col-md-12 col-12">
                    <div
                      className="text-start my-1 ms-1"
                      style={{
                        fontSize: "2.2dvh",
                        fontWeight: "600",
                        color: "#ff5c09",
                      }}
                    >
                      <Link to="/home">Home</Link> &#8811;
                      <Link onClick={cancelHandler}> Cook Profile</Link> &#8811;
                      Edit Chef Profile
                    </div>
                    <Form autoComplete="off" noValidate>
                      <Row className="mt-4 gx-3 d-flex justify-content-center ">
                        <Col lg={12} xs={11} className="mb-4">
                          <Row className="=d-flex justify=-content-center align-items-center">
                            <div
                              style={{
                                height: "100%",
                                width: "100%",
                                backgroundColor: "white",
                                borderRadius: "15px",
                                padding: "20px 40px",
                              }}
                            >
                              <Col lg={12}>
                                <Row>
                                  <Col lg={6} className="p-3">
                                    <Form.Group className="my-2">
                                      <label className="mb-2 label">
                                        Cuisines Known *
                                      </label>

                                      <Typeahead
                                        clearButton
                                        id="basic-typeahead-multiple"
                                        labelKey="name"
                                        multiple
                                        className="input1"
                                        onChange={handleChefCuisineChange}
                                        options={options}
                                        placeholder="Cuisines known"
                                        selected={chefCuisines}
                                        isInvalid={!!errors.chefCuisines}
                                      />

                                      <Form.Control.Feedback type="invalid">
                                        {errors.chefCuisines}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6} className="p-3">
                                    <Form.Group className="my-2">
                                      <label className="mb-2 label">
                                        Experience *
                                      </label>
                                      <Form.Control
                                        type="number"
                                        name="chefExperienceVal"
                                        value={chefExperienceVal}
                                        isInvalid={!!errors.chefExperienceVal}
                                        isValid={!errors.chefExperienceVal}
                                        onChange={handleChange}
                                        className="input1"
                                        onWheel={(e) => e.target.blur()}
                                        placeholder="Enter experience"
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.chefExperienceVal}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6} className="p-3">
                                    <Form.Group className="my-2">
                                      <label className="mb-2 label">
                                        Current Company
                                      </label>
                                      <Form.Control
                                        type="text"
                                        name="currentCompany"
                                        value={currentCompany}
                                        isInvalid={!!errors.currentCompany}
                                        isValid={!errors.currentCompany}
                                        onChange={handleChange}
                                        className="input1"
                                        min="0"
                                        placeholder="Enter current company"
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.currentCompany}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6} className="p-3">
                                    <Form.Group className="my-2">
                                      <label className="mb-2 label">
                                        Current Salary (Per Month) *
                                      </label>
                                      <Form.Control
                                        type="text"
                                        name="currentSalaryVal"
                                        value={currentSalaryVal}
                                        isInvalid={!!errors.currentSalaryVal}
                                        isValid={!errors.currentSalaryVal}
                                        onChange={handleChange}
                                        onWheel={(e) => e.target.blur()}
                                        className="input1"
                                        maxlength={6}
                                        placeholder="Enter current salary"
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.currentSalaryVal}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6} className="p-3">
                                    <Form.Group className="my-2">
                                      <label className="mb-2 label">
                                        Expected Salary (Per Month) *
                                      </label>
                                      <Form.Control
                                        type="text"
                                        name="expectedSalaryVal"
                                        value={expectedSalaryVal}
                                        isInvalid={!!errors.expectedSalaryVal}
                                        isValid={!errors.expectedSalaryVal}
                                        onChange={handleChange}
                                        onWheel={(e) => e.target.blur()}
                                        className="input1"
                                        maxlength={6}
                                        placeholder="Enter expected salary"
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.expectedSalaryVal}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6} className="p-3">
                                    <Form.Group className="my-2">
                                      <label className="mb-2 label">
                                        Skills
                                      </label>
                                      <Form.Control
                                        type="text"
                                        name="skillsVal"
                                        value={skillsVal}
                                        isInvalid={!!errors.skillsVal}
                                        isValid={!errors.skillsVal}
                                        onChange={(e) => {
                                          setSkillsVal(
                                            e.target.value.split(",")
                                          ); // Split the string into an array
                                          handleChange(e);
                                        }}
                                        className="input1"
                                        placeholder="Enter skills"
                                      />

                                      <Form.Control.Feedback type="invalid">
                                        {errors.skillsVal}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>

                                  <Col lg={6} className="p-3">
                                    <Form.Group>
                                      <label className="mb-2 label">
                                        Current City *
                                      </label>

                                      <Form.Control
                                        type="text"
                                        name="currentCityName"
                                        value={currentCityName}
                                        isInvalid={!!errors.currentCityName}
                                        isValid={!errors.currentCityName}
                                        onChange={handleChange}
                                        className="input1"
                                        ref={inputRef}
                                        placeholder="Enter current city"
                                      />

                                      <Form.Control.Feedback type="invalid">
                                        {errors.currentCityName}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>

                                  <Col lg={6} className="p-3">
                                    <Form.Group className=" justify-content-start align-items-center">
                                      <label className="mb-2 label">
                                        Willing to relocate *
                                      </label>
                                      <Row className="ps-3">
                                        <Col lg={3} md={3} xs={6}>
                                          <Form.Check
                                            type="switch"
                                            id="yes"
                                            label="Yes"
                                            name="relocate"
                                            value={relocate}
                                            onChange={() => setRelocate(1)}
                                            checked={relocate === 1}
                                            className="switchBtn"
                                            required
                                          />
                                        </Col>
                                        <Col lg={3} md={3} xs={6}>
                                          <Form.Check
                                            type="switch"
                                            id="no"
                                            label="No"
                                            name="relocate"
                                            value={relocate}
                                            onChange={() => setRelocate(0)}
                                            checked={relocate === 0}
                                            className="switchBtn"
                                            required
                                          />
                                        </Col>
                                      </Row>
                                      <Form.Control.Feedback type="invalid">
                                        You must agree before submitting.
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6} className="p-3">
                                    <Form.Group className=" justify-content-start align-items-center">
                                      <label className="mb-2 label">
                                        Looking for job *
                                      </label>
                                      <Row className="ps-3">
                                        <Col lg={3} md={3} xs={6}>
                                          <Form.Check
                                            type="switch"
                                            id="yes"
                                            label="Yes"
                                            name="householdVesselWash"
                                            value={jobSeeking}
                                            onChange={() => setjobSeeking(1)}
                                            checked={jobSeeking === 1}
                                            className="switchBtn"
                                          />
                                        </Col>
                                        <Col lg={3} md={3} xs={6}>
                                          <Form.Check
                                            type="switch"
                                            id="no"
                                            label="No"
                                            name="householdVesselWash"
                                            value={jobSeeking}
                                            onChange={() => setjobSeeking(0)}
                                            checked={jobSeeking === 0}
                                            className="switchBtn"
                                          />
                                        </Col>
                                      </Row>
                                    </Form.Group>
                                    {/* <span
                          style={{
                            color: "red",
                            display: !jobSeeking ? "block" : "none",
                            textAlign: "center",
                            fontSize: "2dvh",
                          }}
                        >
                          {!jobSeeking ? "Please select" : ""}
                        </span> */}
                                  </Col>
                                  <Col lg={6} className="p-3">
                                    <Form.Group>
                                      <label className="mb-2 label">
                                        Resume
                                      </label>

                                      <Form.Control
                                        type="file"
                                        name="resume"
                                        accept=".jpg, .jpeg, .png, .pdf, .doc, .docx"
                                        isInvalid={!!resumError}
                                        isValid={!resumError}
                                        onChange={(e) => imageUpload(e)}
                                        className="input1"
                                        placeholder="Upload resume"
                                      />

                                      <Form.Control.Feedback type="invalid">
                                        {resumError}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </Col>
                              <Row className="d-flex justify-content-center mt-5">
                                <Col lg={4} xs={8}>
                                  <div className="d-flex justify-content-around my-2 align-items-center">
                                    <button
                                      onClick={cancelHandler}
                                      className="btn cancelBtn"
                                    >
                                      Cancel
                                    </button>
                                    &nbsp; &nbsp;
                                    <Button
                                      onClick={submitHandler}
                                      disabled={isdisabled}
                                      type="submit"
                                      className="saveBtn my-2"
                                    >
                                      Save
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default EditChefProfile;
