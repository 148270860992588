import React, { useEffect, useState } from "react";
import { Col, Row, Form, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { FaTimes } from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ChangeRoleCook } from "../../store/slices/cook";

function ChangeRoleModal({ showModal, data, setShowModal, id }) {
  const [chef, setCook] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [houseCook, setHouseCook] = useState(false);
  const [partyCook, setPartyCook] = useState(false);
  const [showModal1, setshowModal1] = useState(false);
  const handleCloseModal = () => {
    setShowModal(false);
    setshowModal1(false);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate("");
  const [errors, setErrors] = useState("");
  const [changeError, setChangeError] = useState("");
  const [isChange, setIsChange] = useState(false);
  const [notoneSelect, setNotselect] = useState();
  const [rolesFrom, setRolesFrom] = useState({
    chef: 0,
    houseCook: 0,
    partyCook: 0,
  });
  const [rolesTo, setRolesTo] = useState({
    chef: 0,
    houseCook: 0,
    partyCook: 0,
  });

  const rolecomeFrom = [];
  const rolegoneTo = [];

  useEffect(() => {
    if (data?.cookType === 2) {
      setCook(true);
      setRolesFrom((prevRoles) => ({
        ...prevRoles,
        chef: 1,
      }));
      setRolesTo((prevRoles) => ({
        ...prevRoles,
        chef: 1,
      }));
    }
    if (data?.partyCook === 1) {
      setPartyCook(true);
      setRolesFrom((prevRoles) => ({
        ...prevRoles,
        partyCook: 1,
      }));
      setRolesTo((prevRoles) => ({
        ...prevRoles,
        partyCook: 1,
      }));
    }

    if (data?.cookType === 1) {
      setHouseCook(true);
      setRolesFrom((prevRoles) => ({
        ...prevRoles,
        houseCook: 1,
      }));
      setRolesTo((prevRoles) => ({
        ...prevRoles,
        houseCook: 1,
      }));
    }
  }, [data]);

  const handleToggleCook = () => {
    setNotselect("");
    setCook((prevCook) => {
      const newCook = !prevCook;
      setRolesTo({
        ...rolesTo,
        chef: newCook ? 1 : 0,
      });
      if (newCook && houseCook) {
        setErrors("Both House Cook and Chef can't be selected");
      } else {
        setErrors("");
      }
      return newCook;
    });

    setChangeError("");
  };

  const handleToggleHouseCook = () => {
    setNotselect("");
    setHouseCook((prevHouseCook) => {
      const newHouseCook = !prevHouseCook;
      setRolesTo({
        ...rolesTo,
        houseCook: newHouseCook ? 1 : 0,
      });
      if (chef && newHouseCook) {
        setErrors("Both House Cook and Chef can't be selected");
      } else {
        setErrors("");
      }
      return newHouseCook;
    });

    setChangeError("");
  };

  const handleTogglePartyCook = () => {
    setNotselect("");
    const newPartyCook = !partyCook;
    setPartyCook(!partyCook);
    setRolesTo({
      ...rolesTo,
      partyCook: newPartyCook ? 1 : 0,
    });
    setChangeError("");
  };
  useEffect(() => {
    // Check if current values are different from the initial values
    const isCookChanged = rolesFrom.chef !== rolesTo.chef;
    const isHouseCookChanged = rolesFrom.houseCook !== rolesTo.houseCook;
    const isPartyCookChanged = rolesFrom.partyCook !== rolesTo.partyCook;

    // Set isChange to true if any of the values is different
    setIsChange(isCookChanged || isHouseCookChanged || isPartyCookChanged);
  }, [rolesFrom, rolesTo]);

  const HandleSubmit = (e) => {
    e.preventDefault();

    if (!chef && !houseCook && !partyCook) {
      setNotselect("Please select atleast one role");
    }
    if (!errors && isChange && (chef || houseCook || partyCook)) {
      setshowModal1(true);
      setShowModal(false);
    } else if (!errors && !isChange && !notoneSelect) {
      setChangeError("Please change roles to submit");
    }
    Object.keys(rolesFrom).forEach((role) => {
      if (rolesFrom[role] === 1) {
        if (role === "chef") {
          rolecomeFrom.push("Chef");
        }
        if (role === "houseCook") {
          rolecomeFrom.push("House Cook");
        }
        if (role === "partyCook") {
          rolecomeFrom.push("Party Cook");
        }
      }
    });

    // Map rolesTo values and push those that are 1 into rolegoneTo
    Object.keys(rolesTo).forEach((role) => {
      if (rolesTo[role] === 1) {
        if (role === "chef") {
          rolegoneTo.push("Chef");
        }
        if (role === "houseCook") {
          rolegoneTo.push("House Cook");
        }
        if (role === "partyCook") {
          rolegoneTo.push("Party Cook");
        }
      }
    });
    setModalMessage(
      "Cook type will be changed from " +
        rolecomeFrom.join(" and ") +
        " to " +
        rolegoneTo.join(", ")
    );
  };

  const ChangeRolefunc = () => {
    const item = {
      memberType: 1,
      cookType: chef ? 2 : houseCook ? 1 : 0,
      partyCook: partyCook ? 1 : 0,
      cookId: id,
    };
    dispatch(ChangeRoleCook(item))
      .unwrap()
      .then((data) => {
        handleCloseModal();

        alert(data?.user?.message);
        navigate(-1);
      })
      .catch(({ message }) => {});
  };

  return (
    <>
      <Modal
        show={showModal1}
        align="center"
        onHide={handleCloseModal}
        style={{ top: "20%", left: "40%", maxWidth: "400px" }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header align="center">
          <Modal.Title
            align="center"
            style={{ fontSize: "20px" }}
          ></Modal.Title>
        </Modal.Header>
        <Modal.Body align="center">
          <h6>{modalMessage}</h6>
          <Button
            type="cancel"
            className="cancelBtn m-2"
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button
            className="saveBtn m-2"
            style={{ width: "60px" }}
            onClick={ChangeRolefunc}
          >
            Ok
          </Button>
        </Modal.Body>
      </Modal>

      <Modal
        style={{ left: "5%" }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={handleCloseModal}
      >
        <div className="d-flex justify-content-end">
          <div onClick={handleCloseModal} style={{ cursor: "pointer" }}>
            <FaTimes />
          </div>
        </div>
        <Modal.Body
          align="left"
          style={{ maxHeight: "auto", overflowY: "auto" }}
        >
          <Row>
            <Col lg={11} xs={11} sm={11} className="card text-center p-3 ms-3">
              <Form
                noValidate
                autoComplete="off"
                className="text-center mt-3 p-2"
                style={{
                  height: "100%",
                  width: "100%",
                  backgroundColor: "",
                  borderRadius: "0px 15px 15px 0px",
                }}
              >
                <Col lg={10} xl={10}>
                  <div className="d-flex justify-content-between p-1">
                    <div className="text-start">
                      <p className="mb-1" style={{ textWrap: "wrap" }}>
                        Chef
                      </p>
                    </div>
                    <div className="mt-1">
                      <Form>
                        <Form.Check
                          type="switch"
                          id="notification-status"
                          style={{ height: "4rem", width: "4rem" }}
                          checked={chef === true}
                          onChange={() => handleToggleCook()}
                        />
                      </Form>
                    </div>
                  </div>
                </Col>
                <Col lg={10} xl={10}>
                  <div className="d-flex justify-content-between p-1">
                    <div className="text-start">
                      <p className="mb-1" style={{ textWrap: "wrap" }}>
                        House Cook
                      </p>
                    </div>

                    <Form>
                      <Form.Check
                        type="switch"
                        id="notification-status"
                        style={{ height: "4rem", width: "4rem" }}
                        checked={houseCook === true}
                        onChange={() => handleToggleHouseCook()}
                      />
                    </Form>
                  </div>
                </Col>
                <Col lg={10} xl={10}>
                  <div className="d-flex justify-content-between p-1">
                    <div className="text-start">
                      <p className="mb-1" style={{ textWrap: "wrap" }}>
                        Party Cook
                      </p>
                    </div>

                    <Form>
                      <Form.Check
                        type="switch"
                        id="notification-status"
                        style={{ height: "4rem", width: "4rem" }}
                        checked={partyCook === true}
                        onChange={() => handleTogglePartyCook()}
                      />
                    </Form>
                  </div>
                </Col>

                <p style={{ color: "#dc3545" }} align="left">
                  {errors || changeError || notoneSelect}
                </p>

                <Col lg={10} xl={10} className="mt-3">
                  <Form.Group>
                    <Button
                      type="cancel"
                      className="cancelBtn"
                      onClick={handleCloseModal}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      className="saveBtn ms-2"
                      onClick={HandleSubmit}
                    >
                      Submit
                    </Button>
                  </Form.Group>
                </Col>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ChangeRoleModal;
