import React, { useState } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import Navbartop from "./navbar";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { GetRoles } from "../store/slices/cook";
import { editCseAccounts, getSingleCse } from "../store/slices/cse";
import { Link } from "react-router-dom";

function EditCsePlan() {
  const { id } = useParams();
  const [errors, setErrors] = useState("");
  const [roles, setRoles] = useState([]);
  const [role,setRole] = useState()
  const [isdisabled, setisDisabled] = useState(false);
  const isValidName = (name) => {
    return /^(?=.*[a-zA-Z])[a-zA-Z0-9'@& -_]{4,}$/.test(name);
  };

  const [data, setData] = useState({
    name: "",
    username: "",
    roleId: "",
    status: 1,
    houseCookAccess: 0,
    partyCookAccess: 0,
    chefAccess: 0,
    cateringAccess: 0,
    employerAccess: 0,
    clientAccess: 0,
  });

  const housecookAcees = (e, val) => {
    if (val === "yes") {
      setData({
        ...data,
        houseCookAccess: 1,
      });
    } else {
      setData({
        ...data,
        houseCookAccess: 0,
      });
    }
  };

  const housepartyAcees = (e, val) => {
    if (val === "yes") {
      setData({
        ...data,
        partyCookAccess: 1,
      });
    } else {
      setData({
        ...data,
        partyCookAccess: 0,
      });
    }
  };

  const housechefAcees = (e, val) => {
    if (val === "yes") {
      setData({
        ...data,
        chefAccess: 1,
      });
    } else {
      setData({
        ...data,
        chefAccess: 0,
      });
    }
  };

  const housecateringAcees = (e, val) => {
    if (val === "yes") {
      setData({
        ...data,
        cateringAccess: 1,
      });
    } else {
      setData({
        ...data,
        cateringAccess: 0,
      });
    }
  };
  const houseemployerAcees = (e, val) => {
    if (val === "yes") {
      setData({
        ...data,
        employerAccess: 1,
      });
    } else {
      setData({
        ...data,
        employerAccess: 0,
      });
    }
  };

  const houseclientAcees = (e, val) => {
    if (val === "yes") {
      setData({
        ...data,
        clientAccess: 1,
      });
    } else {
      setData({
        ...data,
        clientAccess: 0,
      });
    }
  };

  useEffect(() => {
    dispatch(GetRoles())
      .unwrap()
      .then((data) => {
        setRoles(data?.users?.data);
      })
      .catch(({ message }) => {});
  }, []);

  useEffect(() => {
    dispatch(getSingleCse(id))
      .unwrap()
      .then((data) => {
        setData(data.users.data);
        setRole(data.users.data.roleId.id)
      })
      .catch(({ message }) => {});
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const validationErrors = { ...errors };

    switch (name) {
      case "name":
        setData({
          ...data,
          name: value,
        });
        validationErrors[name] =
          value === "" || !isValidName(value) ? "Please enter valid name" : "";
        if (value !== "" && isValidName(value)) {
          validationErrors[name] = "";
        }
        break;
      case "username":
        setData({
          ...data,
          username: value,
        });
        validationErrors[name] =
          value === "" || !isValidName(value)
            ? "Please enter valid username"
            : "";
        if (value !== "" && isValidName(value)) {
          validationErrors[name] = "";
        }
        break;

      case "role":
       setRole(value)
        setData({
          ...data,
          role:value
        });

        validationErrors[name] =
          value.trim() === "" ? "Please select role" : "";
        break;
  
      default:
        break;
      
    }

    setErrors(validationErrors);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate("");

  const submitHandler = (e) => {
    e.preventDefault();
    const validationErrors = {};
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    if (!data.name) {
      validationErrors.name = "Please enter  name";
    } else if (!isValidName(data.name)) {
      validationErrors.name = "Name should have at least 3 characters";
    }
    if (!data.username) {
      validationErrors.username = "Please enter username";
    } else if (!isValidName(data.username)) {
      validationErrors.username = "Username should have at least 3 characters";
    }

    if (!data.roleId) {
      validationErrors.role = "Please select role";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    } else {
      dispatch(editCseAccounts(data))
        .unwrap()
        .then((data) => {
          alert(data.users.message);
          navigate("/cse");
        })
        .catch(({ message }) => {
          alert(message);
        });
    }

    // }
  };

  const cancelHandler = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const ChangeCsePassword = () => {
    navigate(`/change-cse-password/${id}`);
  };

  useEffect(() => {
    document.title = "CookandChef Admin | Edit CSE";
  }, []);




  return (
    <>
      <Navbartop />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div
                    className="text-start my-2 ms-1"
                    style={{
                      fontSize: "2.2dvh",
                      fontWeight: "600",
                      color: "#ff5c09",
                    }}
                  >
                    <Link to="/home">Home</Link> &#8811;&nbsp;
                    <Link to="/cse">CSE</Link> &#8811; Edit CSE
                  </div>
                  <div className="card">
                    <div className="card-header d-flex justify-content-between">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Edit CSE Account</h4>
                      </div>

                      <div align="right">
                        <u
                          onClick={ChangeCsePassword}
                          style={{ color: "#ff5c09", cursor: "pointer" }}
                        >
                          Change Password
                        </u>
                      </div>
                    </div>

                    <section className="form-section">
                      <Form className="mx-4" onSubmit={submitHandler}>
                        <Row className="my-4 justify-content-center">
                          <Row>
                            <Col lg={6} className="my-2 mt-2">
                              <Form.Group>
                                <Form.Label>Name *</Form.Label>

                                <Form.Control
                                  name="name"
                                  value={data.name}
                                  onChange={handleChange}
                                  className="inout1"
                                  isInvalid={!!errors.name}
                                  isValid={!errors.name}
                                  type="text"
                                  placeholder="Enter name"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.name}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={6} className="my-2">
                              <Form.Group>
                                <Form.Label>User Name *</Form.Label>

                                <Form.Control
                                  name="username"
                                  value={data.username}
                                  onChange={handleChange}
                                  isInvalid={!!errors.username}
                                  isValid={!errors.username}
                                  type="text"
                                  placeholder="Enter user name"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.username}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <Col lg={6} className="my-2">
                              <Form.Group>
                                <Form.Label>Role *</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="role"
                                  className="input1"
                                  value={role}
                                  onChange={handleChange}
                                  isInvalid={!!errors.role}
                                >
                                  <option value="">Select</option>
                                  {roles.map((role, index) => (
                                    <option key={index} value={role?.id}>
                                      {role?.roleName}
                                    </option>
                                  ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {errors.role}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <Row>
                              <Col lg={6} className="p-1">
                                <Form.Group className="justify-content-start align-items-center">
                                  <label className="mt-3 mb-2 ms-3 label">
                                    Housecook Access
                                  </label>

                                  <Row className="ml-4">
                                    <Col lg={3} md={3} xs={6}>
                                      <Form.Check
                                        type="switch"
                                        id="housecook-yes"
                                        label="Yes"
                                        name="houseCookAccess"
                                        checked={data?.houseCookAccess === 1}
                                        onChange={(e) =>
                                          housecookAcees(e, "yes")
                                        }
                                      />
                                    </Col>
                                    <Col lg={3} md={3} xs={6}>
                                      <Form.Check
                                        type="switch"
                                        id="houseCookAccess-no"
                                        label="No"
                                        name="houseCookAccess"
                                        checked={data?.houseCookAccess === 0}
                                        onChange={(e) =>
                                          housecookAcees(e, "no")
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </Form.Group>
                              </Col>
                              <Col lg={6} className="p-1">
                                <Form.Group className="justify-content-start align-items-center">
                                  <label className="mt-3 mb-2 ms-3 label">
                                    Partycook Access{" "}
                                  </label>

                                  <Row className="ml-4">
                                    <Col lg={3} md={3} xs={6}>
                                      <Form.Check
                                        type="switch"
                                        id="partyCookAccess-yes"
                                        label="Yes"
                                        name="partyCookAccess"
                                        checked={data?.partyCookAccess === 1}
                                        onChange={(e) =>
                                          housepartyAcees(e, "yes")
                                        }
                                      />
                                    </Col>
                                    <Col lg={3} md={3} xs={6}>
                                      <Form.Check
                                        type="switch"
                                        id="partyCookAccess-no"
                                        label="No"
                                        name="partyCookAccess"
                                        checked={data?.partyCookAccess === 0}
                                        onChange={(e) =>
                                          housepartyAcees(e, "no")
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={6} className="p-1">
                                <Form.Group className="justify-content-start align-items-center">
                                  <label className="mt-3 mb-2 ms-3 label">
                                    Chef Access
                                  </label>

                                  <Row className="ml-4">
                                    <Col lg={3} md={3} xs={6}>
                                      <Form.Check
                                        type="switch"
                                        id="chefAccess-yes"
                                        label="Yes"
                                        name="chefAccess"
                                        checked={data?.chefAccess === 1}
                                        onChange={(e) =>
                                          housechefAcees(e, "yes")
                                        }
                                      />
                                    </Col>
                                    <Col lg={3} md={3} xs={6}>
                                      <Form.Check
                                        type="switch"
                                        id="chefAccess-no"
                                        label="No"
                                        name="chefAccess"
                                        checked={data?.chefAccess === 0}
                                        onChange={(e) =>
                                          housechefAcees(e, "no")
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </Form.Group>
                              </Col>
                              <Col lg={6} className="p-1">
                                <Form.Group className="justify-content-start align-items-center">
                                  <label className="mt-3 mb-2 ms-3 label">
                                    Catering Access{" "}
                                  </label>

                                  <Row className="ml-4">
                                    <Col lg={3} md={3} xs={6}>
                                      <Form.Check
                                        type="switch"
                                        id="cateringAccess-yes"
                                        label="Yes"
                                        name="cateringAccess"
                                        checked={data?.cateringAccess === 1}
                                        onChange={(e) =>
                                          housecateringAcees(e, "yes")
                                        }
                                      />
                                    </Col>
                                    <Col lg={3} md={3} xs={6}>
                                      <Form.Check
                                        type="switch"
                                        id="cateringAccess-no"
                                        label="No"
                                        name="cateringAccess"
                                        checked={data?.cateringAccess === 0}
                                        onChange={(e) =>
                                          housecateringAcees(e, "no")
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={6} className="p-1">
                                <Form.Group className="justify-content-start align-items-center">
                                  <label className="mt-3 mb-2 ms-3 label">
                                    Employer Access
                                  </label>

                                  <Row className="ml-4">
                                    <Col lg={3} md={3} xs={6}>
                                      <Form.Check
                                        type="switch"
                                        id="employerAccess-yes"
                                        label="Yes"
                                        name="employerAccess"
                                        checked={data?.employerAccess === 1}
                                        onChange={(e) =>
                                          houseemployerAcees(e, "yes")
                                        }
                                      />
                                    </Col>
                                    <Col lg={3} md={3} xs={6}>
                                      <Form.Check
                                        type="switch"
                                        id="employerAccess-no"
                                        label="No"
                                        name="employerAccess"
                                        checked={data?.employerAccess === 0}
                                        onChange={(e) =>
                                          houseemployerAcees(e, "no")
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </Form.Group>
                              </Col>
                              <Col lg={6} className="p-1">
                                <Form.Group className="justify-content-start align-items-center">
                                  <label className="mt-3 mb-2 ms-3 label">
                                    Client Access{" "}
                                  </label>

                                  <Row className="ml-4">
                                    <Col lg={3} md={3} xs={6}>
                                      <Form.Check
                                        type="switch"
                                        id="clientAccess-yes"
                                        label="Yes"
                                        name="clientAccess"
                                        checked={data?.clientAccess === 1}
                                        onChange={(e) =>
                                          houseclientAcees(e, "yes")
                                        }
                                      />
                                    </Col>
                                    <Col lg={3} md={3} xs={6}>
                                      <Form.Check
                                        type="switch"
                                        id="clientAccess-no"
                                        label="No"
                                        name="clientAccess"
                                        checked={data?.clientAccess === 0}
                                        onChange={(e) =>
                                          houseclientAcees(e, "no")
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </Form.Group>
                              </Col>
                            </Row>
                          </Row>
                        </Row>
                        <Row className="justify-content-center mt-5">
                          <Col xs={6} md={4} lg={2} xl={2} xxl={2}>
                            <button
                              onClick={cancelHandler}
                              className="btn cancelBtn"
                            >
                              Cancel
                            </button>
                          </Col>
                          <Col xs={6} md={4} lg={2} xl={2} xxl={2}>
                            <Button
                              disabled={isdisabled}
                              className="btn saveBtn mx-2"
                              type="submit"
                            >
                              Save
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditCsePlan;
