import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

import Navbartop from "./navbar";
import { getCuisines, getQualification } from "../store/slices/config";
import { EditJobsDetails, getJobsDetails } from "../store/slices/jobs";

function EditJobDetails() {
  const { id } = useParams();
  localStorage.setItem("employerId", id);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cuisines, setCuisines] = useState([]);
  const [selectedCuisines, setSelcetedCisines] = useState([]);
  const [education, setEducation] = useState([]);
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [successModal, setsuccessModal] = useState(false);
  const [isdisabled, setisDisabled] = useState(false);
  const [citycoord, setcitycoord] = useState([]);
  const inputRef = useRef(null);
  const disignationRef = useRef(null);
  const salaryRef = useRef(null);
  const urgencyRef = useRef(null);
  const experienceRef = useRef(null);
  const noofOpeningsRef = useRef(null);
  const cuisineRef = useRef(null);
  const foodRef = useRef(null);
  const accommodationRef = useRef(null);
  const pincodeRef = useRef(null);
  const jobdescRef = useRef(null);

  useEffect(() => {
    // Check if the input field should be focused
    if (isEditing && inputRef.current) {
      inputRef.current.focus();

      // Move the cursor to the end of the input value
      const length = inputRef.current.value.length;
      inputRef.current.setSelectionRange(length, length);
    }
  }, [isEditing]);
  const [data, setData] = useState({
    designation: "",
    salary: "",
    experience: "",
    openings: "",
    urgency: "",
    contactNumber: "",
    whatsappUpdate: "",
    status: 1,
    description: "",
    visibility: "",
    pincode: "",
    food: "",
    accommodation: "",
    qualification: "",
    dishes: "",
    cuisines: "",
    location: "",
    locationCoordinates: {
      type: "Point",
      coordinates: [],
    },
  });

  const isValidName = (name) => {
    // validating the name with one cap letter and 6 char of length
    return /^[a-zA-Z0-9@&'’ -]{3,}$/.test(name);
  };

  const isValidmobile = (mobileNumber) => {
    return /^[0-9]{10}$/.test(mobileNumber);
  };

  const isValidcurrentCity = (location) => {
    return /^.{3,180}$/.test(location);
  };

  const isValidPincode = (pincode) => {
    // validating the name with one cap letter and 6 char of length
    return /^[1-9][0-9]{5}$/.test(pincode);
  };

  const isValidExperienceVal = (Experience) => {
    return /^(0[1-9]|[1-4][0-9]?|50)$/.test(Experience);
  };

  const isValidSalary = (SalaryVal) => {
    return /^(?:1[89]\d{3,}|[2-9]\d{4,}|100000|[1-9]\d{5,})$/.test(SalaryVal);
  };

  const isValidOpenings = (openingsVal) => {
    return /^(0[1-9]|[1-9][0-9]?|100)$/.test(openingsVal);
  };

  const isValidDescription = (descriptionVal) => {
    return /^(?!\s)[\s\S]{3,1000}$/.test(descriptionVal);
  };

  useEffect(() => {
    dispatch(getJobsDetails(id))
      .unwrap()
      .then((data) => {
        setData(data.jobs.data[0]);
        setSelcetedCisines(data?.jobs?.data[0].cuisines);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  }, [dispatch]);

  const cityinputRef = useRef(null);

  useEffect(() => {
    if (cityinputRef.current) {
      const autoCompleteCity = new window.google.maps.places.Autocomplete(
        cityinputRef.current,
        {
          componentRestrictions: { country: "in" },
        }
      );

      autoCompleteCity.addListener("place_changed", () => {
        const place = autoCompleteCity.getPlace();
        if (!place.geometry || !place.geometry.location) {
          setcitycoord([]);
          setErrors({
            ...errors,
            location: "Please select location from the suggestion drop-down",
          });
        } else {
          setErrors({
            ...errors,
            location: "",
          });
          const coordinates = [
            place.geometry.location.lng(),
            place.geometry.location.lat(),
          ];
          setcity(coordinates, place.name);
          setcitycoord(coordinates);
        }
      });
      cityinputRef.current.addEventListener("blur", () => {
        const place = autoCompleteCity.getPlace();
        const inputValue = cityinputRef.current.value.trim();
        if (inputValue && (!place?.geometry || !place?.geometry?.location)) {
          setcitycoord([]);
          setErrors({
            ...errors,
            location: "Please select location from the suggestion drop-down",
          });
        }
      });
    }
  }, []);

  const setcity = (coordinates, city) => {
    setData((prevData) => ({
      ...prevData,
      location: city,
      locationCoordinates: {
        type: "Point",
        coordinates: coordinates,
      },
    }));
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);

    const validationErrors = {};
    if (!data.designation) {
      validationErrors.designation = "Please enter designation";
    } else if (!isValidName(data.designation)) {
      validationErrors.designation =
        "Designation should be atleast 3 characters";
    }
    if (!data.salary) {
      validationErrors.salary = "Please enter salary";
    } else if (!isValidSalary(data.salary)) {
      validationErrors.salary =
        "We only post jobs with a minimum salary Rs.18000";
    }

    if (data.food === "") {
      validationErrors.food = "Please select";
    }
    if (data.accommodation === "") {
      validationErrors.accommodation = "Please select";
    }

    if (!data.experience) {
      validationErrors.experience = "Please enter experience";
    } else if (!isValidExperienceVal(data.experience)) {
      validationErrors.experience = "Max. experience allowed is 50 years";
    }

    if (!data.urgency) {
      validationErrors.urgency = "Please enter urgency level";
    }
    if (!data.contactNumber) {
      validationErrors.contactNumber = "Please enter your contact number";
    } else if (!isValidmobile(data.contactNumber)) {
      validationErrors.mobileNumber = "Please enter valid mobile Number";
    }
    if (!data.description) {
      validationErrors.description = "Please enter job description";
    } else if (!isValidDescription(data.description)) {
      validationErrors.description = "Please enter atleast 3 characters";
    }
    if (!data.pincode) {
      validationErrors.pincode = "Please enter pincode";
    } else if (!isValidPincode(data.pincode)) {
      validationErrors.pincode = "Pincode must be atleast 6 digits";
    }

    if (!data.location) {
      validationErrors.location = "Please enter location";
    } else if (
      !isValidcurrentCity(data.location) ||
      !data?.locationCoordinates?.coordinates?.length
    ) {
      validationErrors.location = "Please enter valid location";
    }

    if (!selectedCuisines?.length) {
      validationErrors.cuisines = "Please enter cuisine";
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      if (validationErrors.designation) {
        disignationRef.current.focus();
      } else if (validationErrors.salary) {
        salaryRef.current.focus();
      } else if (validationErrors.urgency) {
        urgencyRef.current.focus();
      } else if (validationErrors.experience) {
        experienceRef.current.focus();
      } else if (validationErrors.openings) {
        noofOpeningsRef.current.focus();
      } else if (validationErrors.contactNumber) {
        inputRef.current.focus();
      } else if (validationErrors.food) {
        foodRef.current.focus();
      } else if (validationErrors.accommodation) {
        accommodationRef.current.focus();
      } else if (validationErrors.pincode) {
        pincodeRef.current.focus();
      } else if (validationErrors.location) {
        cityinputRef.current.focus();
      } else if (validationErrors.description) {
        jobdescRef.current.focus();
      } else if (validationErrors.cuisines) {
      }
      return;
    } else {
      const numberph = parseInt(data?.contactNumber);
      const pin = parseInt(data?.pincode);
      const salary = parseInt(data?.salary);
      const exp = parseInt(data?.experience);
      const vac = parseInt(data?.openings);
      const item = {
        id: id,
        designation: data?.designation || undefined,
        urgency: data?.urgency || undefined,
        status: data?.status || undefined,
        whatsappUpdate: data?.whatsappUpdate,
        description: data?.description || undefined,
        visibility: data?.visibility,
        food: data?.food,
        accommodation: data?.accommodation,
        qualification: data?.qualification,
        dishes: data?.dishes,
        location: data?.location || undefined,
        cuisines: selectedCuisines || undefined,
        locationCoordinates: data?.locationCoordinates || undefined,
        contactNumber: numberph || undefined,
        pincode: pin || undefined,
        salary: salary || undefined,
        openings: vac || undefined,
        experience: exp || undefined,
      };

      dispatch(EditJobsDetails(item))
        .unwrap()
        .then((data) => {
          alert(data.jobs.message);
          setTimeout(() => {
            navigate(-1);
          }, 2000);
        })
        .catch(({ message }) => {});
    }
  };

  React.useEffect(() => {
    dispatch(getQualification())
      .unwrap()
      .then((data) => {
        setEducation(data.user.data);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getCuisines())
      .unwrap()
      .then((data) => {
        setCuisines(data.user.data);
      })
      .catch(({ message }) => {
        // setModalMessage(message);
        // setShowModal(true);
      });
  }, [dispatch]);

  var cuisinoptions = cuisines.map(function (item) {
    return item["cuisineName"];
  });

  useEffect(() => {
    document.title = "CookandChef Admin | Edit Job Details";
  }, []);

  const handleChange = (e) => {
    const validationErrors = { ...errors };
    const { name, checked } = e.target;
    let value = e.target.value;
    switch (name) {
      case "designation":
        setData({
          ...data,
          designation: value,
        });
        validationErrors[name] =
          value === "" || !isValidName(value)
            ? "Designation should be atleast 3 characters"
            : "";
        if (value !== "" && isValidName(value)) {
          validationErrors[name] = "";
        }
        break;
      case "salary":
        value = value.replace(/\D/g, "");

        // Limit the length to 10 characters
        if (value.length > 6) {
          value = value.slice(0, 6);
        }
        setData({
          ...data,
          salary: value,
        });
        validationErrors[name] =
          value === "" || !isValidSalary(value)
            ? "We only post jobs with a minimum salary Rs.18000"
            : "";
        if (value !== "" && isValidSalary(value)) {
          validationErrors[name] = "";
        }
        break;
      case "urgency":
        setData({
          ...data,
          urgency: value,
        });
        validationErrors[name] = value === "" ? "Please enter urgency" : "";
        if (value !== "") {
          validationErrors[name] = "";
        }
        break;
      case "experience":
        value = value.replace(/\D/g, "");

        // Limit the length to 10 characters
        if (value.length > 2) {
          value = value.slice(0, 2);
        }
        setData({
          ...data,
          experience: value,
        });
        validationErrors[name] =
          value === "" || !isValidExperienceVal(value)
            ? "Max. experience allowed is 50 years"
            : "";
        if (value !== "" && isValidExperienceVal(value)) {
          validationErrors[name] = "";
        }
        break;
      case "openings":
        value = value.replace(/\D/g, "");

        // Limit the length to 10 characters
        if (value.length > 3) {
          value = value.slice(0, 3);
        }
        setData({
          ...data,
          openings: value,
        });
        validationErrors[name] =
          value !== "" && !isValidOpenings(value)
            ? "Please enter a value between 1 to 100"
            : "";
        if (value !== "" && isValidOpenings(value)) {
          validationErrors[name] = "";
        }
        break;
      case "contactNumber":
        value = value.replace(/\D/g, "");

        // Limit the length to 10 characters
        if (value.length > 10) {
          value = value.slice(0, 10);
        }
        setData({
          ...data,
          contactNumber: value,
        });
        validationErrors[name] =
          value === "" || !isValidmobile(value)
            ? "Please enter valid contact number"
            : "";
        if (!value === "" && isValidmobile(value)) {
          delete validationErrors[name];
        }
        break;
      case "description":
        e.target.style.height = "auto";
        e.target.style.height = e.target.scrollHeight + "px";
        setData({
          ...data,
          description: value,
        });
        validationErrors[name] =
          value === "" || !isValidDescription(value)
            ? "Description must be atleast 3 characters"
            : "";
        if (!value === "" && isValidDescription(value)) {
          delete validationErrors[name];
        }
        break;

      case "qualification":
        setData({
          ...data,
          qualification: value,
        });
        validationErrors[name] =
          value === "" ? "Please enter qualification" : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;
      case "dishes":
        setData({
          ...data,
          dishes: value,
        });

        break;
      case "location":
        setData({
          ...data,
          location: value,
        });
        validationErrors[name] =
          value === "" ? "Please enter valid location" : "";
        if (!value === "" && isValidcurrentCity(value)) {
          delete validationErrors[name];
        }
        break;

      case "cuisine":
        setSelcetedCisines(value);
        validationErrors[name] = value === "" ? "Please enter cuisine" : "";
        if (!value === "") {
          delete validationErrors[name];
        }
        break;

      case "pincode":
        value = value.replace(/\D/g, "");

        // Limit the length to 10 characters
        if (value.length > 6) {
          value = value.slice(0, 6);
        }
        setData({
          ...data,
          pincode: value,
        });
        validationErrors[name] =
          value === "" || !isValidPincode(value) ? "Please enter pincode" : "";
        if (!value === "" && isValidPincode(value)) {
          delete validationErrors[name];
        }
        break;

      default:
        break;
    }

    setErrors(validationErrors);
  };

  const handleChefCuisineChange = (selected) => {
    const validationErrors = { ...errors };
    setSelcetedCisines(selected);
    validationErrors.cuisines =
      selected?.length === 0 ? "Please enter cuisine" : "";
    if (!selected?.length === 0) {
      delete validationErrors.cuisines;
    }

    setErrors(validationErrors);
  };

  const cancelHandler = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const handleVisibleChange = (e) => {
    setData({
      ...data,
      visibility: parseInt(e.target.value),
    });
  };

  const handleAccoChange = (e, val) => {
    setErrors({
      ...errors,
      accommodation: "",
    });
    let acco = val == "yes" ? 1 : 0;
    setData({
      ...data,
      accommodation: acco,
    });
  };

  const handleFoodChange = (e, val) => {
    setErrors({
      ...errors,
      food: "",
    });
    let foo = val == "yes" ? 1 : 0;
    setData({
      ...data,
      food: foo,
    });
  };

  const recieveWatsapp = (e) => {
    setData({
      ...data,
      whatsappUpdate: e.target.checked ? 1 : 0,
    });
  };

  return (
    <>
      <div>
        <Navbartop />

        <div className="container-fluid pb-5 ms-2 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-lg-10 col-md-12 col-12">
                    <div
                      className="text-start my-2 ms-1"
                      style={{
                        fontSize: "2.2dvh",
                        fontWeight: "600",
                        color: "#ff5c09",
                      }}
                    >
                      <Link to="/home">Home</Link> &#8811;{" "}
                      <Link onClick={cancelHandler}>Job Details</Link> &#8811;
                      Edit Job Details
                    </div>

                    <Form
                      autoComplete="off"
                      noValidate
                      onSubmit={submitHandler}
                    >
                      <Row className="d-flex justify-content-center mt-4 card p-3">
                        <Col lg={12} xs={12} md={12} className="mb-4">
                          <h5 className="text-left heading orange">
                            Job Details
                          </h5>
                          <Row className="d-flex justify-content-center ">
                            <Col lg={6} className="p-3">
                              <Form.Group>
                                <label className="mb-2 label">
                                  Designation *
                                </label>
                                <Form.Control
                                  name="designation"
                                  value={data?.designation}
                                  onChange={handleChange}
                                  isInvalid={!!errors.designation}
                                  placeholder="e.g Head Chef, Sous Chef"
                                  className="input1"
                                  ref={disignationRef}
                                  type="text"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.designation}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={6} className="p-3">
                              <Form.Group>
                                <label className="mb-2 label">Salary *</label>
                                <Form.Control
                                  placeholder="18000"
                                  name="salary"
                                  type="number"
                                  onChange={handleChange}
                                  value={data?.salary}
                                  onWheel={(e) => e.target.blur()}
                                  ref={salaryRef}
                                  isInvalid={!!errors.salary}
                                  className="input1"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.salary}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={6} className="p-3">
                              <Form.Group>
                                <label className="mb-2 label">Urgency *</label>
                                <Form.Control
                                  as="select"
                                  name="urgency"
                                  className="input1"
                                  value={data.urgency}
                                  onChange={handleChange}
                                  isInvalid={!!errors.urgency}
                                  isValid={errors.urgency}
                                  ref={urgencyRef}
                                >
                                  <option value="" selected disabled hidden>
                                    Select
                                  </option>
                                  <option>Immediately</option>
                                  <option>15 Days</option>
                                  <option>1 Month</option>
                                  <option>2 Months</option>
                                </Form.Control>
                              </Form.Group>
                            </Col>
                            <Col lg={6} className="p-3">
                              <Form.Group>
                                <label className="mb-2 label">
                                  Experience *
                                </label>
                                <Form.Control
                                  name="experience"
                                  value={data?.experience}
                                  placeholder="YY"
                                  className="input1"
                                  isInvalid={!!errors.experience}
                                  type="number"
                                  onChange={handleChange}
                                  ref={experienceRef}
                                  onWheel={(e) => e.target.blur()}
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {errors.experience}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={6} className="p-3">
                              <Form.Group>
                                <label className="mb-2 label">
                                  No. of Openings
                                </label>
                                <Form.Control
                                  value={data?.openings}
                                  name="openings"
                                  placeholder="1 or 2"
                                  isInvalid={!!errors.openings}
                                  type="text"
                                  onChange={handleChange}
                                  ref={noofOpeningsRef}
                                  className="input1"
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {errors.openings}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={6} className="p-3">
                              <Form.Group>
                                <label className="mb-2 label">
                                  Contact Number *
                                </label>
                                <Form.Control
                                  name="contactNumber"
                                  value={data?.contactNumber}
                                  placeholder="contact number"
                                  isInvalid={!!errors.contactNumber}
                                  type="text"
                                  pattern="[0-9]*"
                                  ref={inputRef}
                                  className="input1"
                                  onChange={handleChange}
                                  readOnly={!isEditing}
                                ></Form.Control>
                                {!isEditing ? (
                                  <p
                                    style={{
                                      color: "#a020f0",
                                      cursor: "pointer",
                                      fontStyle: "italic",
                                      marginTop: "-25px",
                                      marginLeft: "90%",
                                    }}
                                    onClick={() => setIsEditing(true)}
                                  >
                                    Edit
                                  </p>
                                ) : (
                                  <p
                                    style={{
                                      color: "#a020f0",
                                      cursor: "pointer",
                                      marginTop: "-25px",
                                      marginLeft: "90%",
                                    }}
                                  >
                                    Edit
                                  </p>
                                )}
                                <Form.Control.Feedback type="invalid">
                                  {errors.contactNumber}
                                </Form.Control.Feedback>
                                <div className="d-flex justify-content align-items-center mt-2">
                                  {" "}
                                  <Form.Check
                                    type="checkbox"
                                    checked={data.whatsappUpdate}
                                    onChange={recieveWatsapp}
                                    style={{ color: "#0051EA" }}
                                    id="myCheckbox"
                                    label="Receive applications on WhatsApp"
                                  />
                                </div>
                              </Form.Group>
                            </Col>
                          </Row>
                          {/*--------------- */}
                          <Row className="d-flex justify-content-center ">
                            <h6
                              className="text-start"
                              style={{ color: "#ff5c09" }}
                            >
                              Skills and Education
                            </h6>
                            <Col lg={6} className="p-3">
                              <Form.Group>
                                <label className="mb-1 label">Cuisines *</label>
                                <Typeahead
                                  id="basic-typeahead-multiple"
                                  labelKey="Cuisine"
                                  multiple
                                  placeholder="Cuisines"
                                  options={cuisinoptions}
                                  onChange={handleChefCuisineChange}
                                  selected={selectedCuisines}
                                  isInvalid={!!errors.cuisines}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.cuisines}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={6} className="p-3">
                              <Form.Group>
                                <label className="mb-3 label">Dishes</label>
                                <Form.Control
                                  value={data?.dishes}
                                  name="dishes"
                                  placeholder="Nan, Paneer"
                                  className="input1"
                                  isInvalid={!!errors.dishes}
                                  type="text"
                                  onChange={handleChange}
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {errors.dishes}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={6} className="p-3">
                              <Form.Group>
                                <label className="mb-2 label">
                                  Qualification
                                </label>
                                <Form.Control
                                  as="select"
                                  name="qualification"
                                  className="input1"
                                  value={data.qualification}
                                  onChange={handleChange}
                                  isInvalid={!!errors.qualification}
                                >
                                  <option value="" selected disabled hidden>
                                    Select
                                  </option>
                                  {education.map((edudata, index) => (
                                    <option
                                      key={index}
                                      value={edudata.iqualificationNamed}
                                    >
                                      {edudata.qualificationName}
                                    </option>
                                  ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {errors.qualification}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={6} />
                          </Row>
                          {/*--------------- */}

                          <Row className="d-flex justify-content-center mt-3">
                            <h6
                              className="text-start"
                              style={{ color: "#ff5c09" }}
                            >
                              Benefits
                            </h6>
                            <Col lg={6} md={6} className="p-3">
                              <Form.Group className="justify-content-start align-items-center">
                                <label className="mb-3 ms-3 label">
                                  Food *
                                </label>

                                <Row className="ms-3">
                                  <Col lg={3} md={3} xs={6}>
                                    <Form.Check
                                      type="switch"
                                      id="food-yes"
                                      label="Yes"
                                      name="food"
                                      ref={foodRef}
                                      checked={data?.food === 1}
                                      onChange={(e) =>
                                        handleFoodChange(e, "yes")
                                      }
                                    />
                                  </Col>
                                  <Col lg={3} md={3} xs={6}>
                                    <Form.Check
                                      type="switch"
                                      id="food-no"
                                      label="No"
                                      name="food"
                                      checked={data?.food === 0}
                                      onChange={(e) =>
                                        handleFoodChange(e, "no")
                                      }
                                    />
                                  </Col>
                                </Row>
                              </Form.Group>
                              <p
                                style={{
                                  color: "#DC3545",
                                  fontSize: "14px",
                                  marginTop: "10px",
                                }}
                              >
                                {errors.food}
                              </p>
                            </Col>
                            <Col lg={6} md={6} className="p-3">
                              <Form.Group className="justify-content-start align-items-center">
                                <label className="mb-3 ms-3 label">
                                  Accommodation *
                                </label>

                                <Row className="ms-3">
                                  <Col lg={3} md={3} xs={6}>
                                    <Form.Check
                                      type="switch"
                                      ref={accommodationRef}
                                      id="accommodation-yes"
                                      label="Yes"
                                      name="accommodation"
                                      checked={data?.accommodation === 1}
                                      onChange={(e) =>
                                        handleAccoChange(e, "yes")
                                      }
                                    />
                                  </Col>
                                  <Col lg={3} md={3} xs={6}>
                                    <Form.Check
                                      type="switch"
                                      id="accommodation-no"
                                      label="No"
                                      name="accommodation"
                                      checked={data?.accommodation === 0}
                                      onChange={(e) =>
                                        handleAccoChange(e, "no")
                                      }
                                    />
                                  </Col>
                                </Row>
                              </Form.Group>
                              <p
                                style={{
                                  color: "#DC3545",
                                  fontSize: "14px",
                                  marginTop: "10px",
                                }}
                              >
                                {errors.accommodation}
                              </p>
                            </Col>
                          </Row>

                          <Row className="d-flex justify-content-center mt-3">
                            <Col lg={6}>
                              <Form.Group className="my-4">
                                <label className="mb-2 label">Pincode *</label>
                                <Form.Control
                                  value={data?.pincode}
                                  name="pincode"
                                  placeholder="110011"
                                  className="input1"
                                  isInvalid={!!errors.pincode}
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  ref={pincodeRef}
                                  onChange={handleChange}
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {errors.pincode}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="my-4">
                                <label className="mb-2 label">Location *</label>
                                <Form.Control
                                  value={data?.location}
                                  name="location"
                                  placeholder="Bangalore, Mumbai"
                                  isInvalid={!!errors.location}
                                  type="text"
                                  ref={cityinputRef}
                                  className="input1"
                                  onChange={handleChange}
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {errors.location}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group controlId="kindOfStand">
                                <Form.Check
                                  type="radio"
                                  id="visibility-all-india"
                                  name="visibility"
                                  value="1" // Add this line
                                  label="Visible to all India (recommended)"
                                  checked={data?.visibility === 1}
                                  onChange={(e) => handleVisibleChange(e)}
                                />

                                <Form.Check
                                  type="radio"
                                  id="visibility-selected-location"
                                  name="visibility"
                                  value="0"
                                  label="Visible to selected location"
                                  checked={data?.visibility === 0}
                                  onChange={(e) => handleVisibleChange(e)}
                                />
                              </Form.Group>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg={12} sm={12}>
                              <Form.Group className="my-4">
                                <label className="mb-2 label">
                                  Job Description *
                                </label>
                                <Form.Control
                                  value={data?.description}
                                  name="description"
                                  placeholder="About the job"
                                  className="input1"
                                  isInvalid={!!errors.description}
                                  as="textarea"
                                  ref={jobdescRef}
                                  rows={1}
                                  maxLength={1000}
                                  style={{ resize: "none" }}
                                  onChange={handleChange}
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {errors.description}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                          {/*--------------- */}

                          <Row
                            className="justify-content-center align-items-center mt-3"
                            align="center"
                          >
                            <Col lg={6} sm={12}>
                              <Button
                                onClick={cancelHandler}
                                className="cancelBtn"
                              >
                                Cancel
                              </Button>
                              &nbsp;&nbsp;
                              <Button
                                type="submit"
                                disabled={isdisabled}
                                className="saveBtn"
                              >
                                Save
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditJobDetails;
