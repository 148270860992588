import React, { useEffect, useState } from "react";
import Navbartop from "./navbar";
import { useDispatch } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { confirmAlert } from "react-confirm-alert";
import { getCseAccounts, DeleteCse } from "../store/slices/cse";
import ShowSkeleton from "../utils/helpers/Skeleton";
import dog from "../Assets/Images/corgi.png";

function CSE() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, settotalData] = useState();
  const [isLoading, setisLoading] = useState(true);

  // react paginate
  const totalPages = 10;
  const itemsPerPage = 25;
  const pagesToShowInitially = 2;

  const handlePageClick = ({ selected }) => {
    const newPage = selected + 1;
    setCurrentPage(newPage);
  };

  useEffect(() => {
    document.title = "CookandChef Admin | CSE";
  }, []);

  React.useEffect(() => {
    const items = {
      limit: itemsPerPage,
      page: currentPage,
    };
    dispatch(getCseAccounts(items))
      .unwrap()
      .then((data) => {
        setData(data.users.data);
        settotalData(data.users.totalDataCount);
        setTimeout(() => {
          setisLoading(false);
        }, [1000]);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch, currentPage]);

  const Delete = async function deletePlan(id) {
    localStorage.setItem("cseId", id);
    dispatch(DeleteCse(id))
      .unwrap()
      .then(() => {
        window.location.reload(false);
      })
      .catch(({ message }) => {
        alert(message);
      });

  };

  const deleteData = (id) => {
    confirmAlert({
      title: "Want to delete?",
      message: "Are you sure you want to delete this plan?",
      buttons: [
        {
          label: "Yes",
          onClick: () => Delete(id),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };
  return (
    <>
      <Navbartop />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div
                    className="text-start my-2 ms-1"
                    style={{
                      fontSize: "2.2dvh",
                      fontWeight: "600",
                      color: "#ff5c09",
                    }}
                  >
                    <Link to="/home">Home</Link> &#8811; CSE
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">CSE</h4>
                        &nbsp;&nbsp;
                        <Button
                          onClick={() => navigate("/add-cse")}
                          style={{ height: "25px" }}
                          className="btn-delete mt-2"
                        >
                          Add CSE
                        </Button>
                      </div>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        {isLoading ? (
                          <Col lg={12} xs={12} md={12} align="center">
                            {ShowSkeleton()}
                          </Col>
                        ) : data?.length === 0 ? (
                          <Col lg={12} md={12} sm={12} xs={12}>
                            <Row
                              className="d-flex justify-items-center align-items-center"
                              style={{
                                backgroundColor: "white",
                                // height: "20dvh",
                                // width: "100%",
                                borderRadius: "15px",
                                padding: "20px",
                              }}
                            >
                              <Col lg={12} align="center">
                                <p style={{ fontSize: "18px" }}>
                                  <b>No Data</b> found!
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        ) : (
                          <Table
                            responsive
                            hover
                            style={{ overflowX: "scroll" }}
                          >
                            <thead>
                              <tr>
                                <th className="text-left">Name</th>
                                <th className="text-left">User Name</th>

                                <th className="text-left">Role</th>

                                <th className="text-center">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.map((val, index) => (
                                <tr key={index}>
                                  <td className="text-left">{val?.name}</td>
                                  <td
                                    className="text-left"
                                    style={{ width: "200px" }}
                                  >
                                    {val?.username}
                                  </td>

                                  <td className="text-left">
                                    {val?.roleId?.roleName}
                                  </td>

                                  <td style={{ textAlign: "center" }}>
                                    <Link
                                      to={`/edit-cse-plan/${
                                        val?._id ? val?._id : null
                                      }`}
                                      className="btn bg-purple col-white"
                                      style={{ height: "25px" }}
                                    >
                                      Edit
                                    </Link>
                                    &nbsp;&nbsp;
                                    <button
                                      onClick={() => deleteData(val._id)}
                                      className="btn btn-delete col-white"
                                      style={{
                                        height: "25px",
                                        background: "#ff5c09",
                                      }}
                                    >
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CSE;
