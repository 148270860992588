import React, { useEffect, useState } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import Navbartop from "./navbar";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { assignplan } from "../store/slices/config";
import { getClientPlan } from "../store/slices/plan";
function AssignClientPlan() {
  const { id } = useParams();
  const [datas, setDatas] = useState([]);
  const [securityKey, setsecurityKey] = useState();
  const [isdisabled, setisDisabled] = useState(false);
  const [planId, setplanId] = useState();
  const [errors, setErrors] = useState({});

  useEffect(() => {
    dispatch(getClientPlan(id))
      .unwrap()
      .then((data) => {
        setDatas(data.users.data);
      })
      .catch(({ message }) => {
        // alert(message);
      });
  }, []);

  useEffect(() => {
    document.title = "CookandChef Admin | Admin Client Plan";
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const validationErrors = { ...errors };
    switch (name) {
      case "securityKey":
        setsecurityKey(value);
        validationErrors[name] = value === "" ? "Please enter pin" : "";
        if (value !== "") {
          delete validationErrors[name];
        }
        break;
      case "planId":
        setplanId(value);
        validationErrors[name] = value.trim === "" ? "Please select plan" : "";
        break;
      default:
        break;
    }
    setErrors(validationErrors);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate("");

  const submitHandler = (e) => {
    const validationErrors = {};
    e.preventDefault();
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    if (!planId) {
      validationErrors.planId = "Please select plan";
    }
    if (!securityKey) {
      validationErrors.securityKey = "Please enter pin";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      let userType = "client";
      let item = {
        userId: id,
        securityKey,
        planId,
        userType,
      };

      dispatch(assignplan(item))
        .unwrap()
        .then(() => {
          alert("Plan added successfully");
          navigate(-1);
        })
        .catch(({ message }) => {
          alert(message);
        });
    }
  };

  const cancelHandler = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <>
      <Navbartop />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div
                    className="text-start my-2 ms-1"
                    style={{
                      fontSize: "2.2dvh",
                      fontWeight: "600",
                      color: "#ff5c09",
                    }}
                  >
                    <Link to="/home">Home</Link> &#8811;&nbsp;
                    <Link onClick={cancelHandler}>Client Details</Link> &#8811;
                    Add Plan
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Add Plan</h4>
                      </div>
                    </div>
                    <section className="form-section">
                      <Form className="mx-4" onSubmit={submitHandler}>
                        <Row className="my-4  justify-content-start">
                          <Row className="d-flex ">
                            <Col lg={5} className="p-1">
                              <Form.Group>
                                <Form.Label>Plan *</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="planId"
                                  className="input1"
                                  value={datas.clientPlanName}
                                  onChange={handleChange}
                                  isInvalid={!!errors.planId}
                                >
                                  <option value="">Select</option>
                                  {datas.map((edudata, index) => (
                                    <option key={index} value={edudata.id}>
                                      {edudata.clientPlanName}
                                    </option>
                                  ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {errors.planId}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <Col lg={5} className="p-1">
                              <Form.Group>
                                <Form.Label>Pin *</Form.Label>

                                <Form.Control
                                  name="securityKey"
                                  value={securityKey}
                                  onChange={handleChange}
                                  isInvalid={!!errors.securityKey}
                                  isValid={
                                    !errors.securityKey && securityKey > 0
                                  }
                                  type="text"
                                  rows={1}
                                  placeholder="Enter pin"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.securityKey}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="justify-content-center mt-5">
                            <Col xs={6} md={4} lg={2} xl={2} xxl={2}>
                              <button
                                className="btn cancelBtn"
                                onClick={cancelHandler}
                              >
                                Cancel
                              </button>
                            </Col>{" "}
                            <Col xs={6} md={4} lg={2} xl={2} xxl={2}>
                              <button
                                className="btn saveBtn ms-2"
                                disabled={isdisabled}
                                type="submit"
                              >
                                Save
                              </button>
                            </Col>
                          </Row>
                        </Row>
                      </Form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AssignClientPlan;
