import React, { useEffect, useState } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import Navbartop from "./navbar";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API_PATHS } from "../utils/constants/api.constants";
import { addTestimonial } from "../store/slices/testimonial";
import { Link } from "react-router-dom";

function AddTestimonial() {
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [message, setMessage] = useState("");
  const [isdisabled, setisDisabled] = useState(false);
  const [errors, setErrors] = useState({});
  const [imageError,setImageError] = useState()

  const isValidName = (name) => {
    // validating the name with one cap letter and 6 char of length
    return /^(?=.*[a-zA-Z])[a-zA-Z0-9'@& -_]{3,}$/.test(name);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const validationErrors = { ...errors };
    switch (name) {
      case "name":
        setName(value);
        validationErrors[name] =
          value === "" || !isValidName(value)
            ? "Name should be atleast 3 characters"
            : "";
        if (value !== "" && isValidName(value)) {
          delete validationErrors[name];
        }
        break;
      case "message":
        e.target.style.height = "auto";
        e.target.style.height = e.target.scrollHeight + "px";
        setMessage(value);
        validationErrors[name] =
          value === "" || !isValidName(value)
            ? "Message should be atleast 4 characters"
            : "";
        if (!value === "" && isValidName(value)) {
          delete validationErrors[name];
        }
        break;
      default:
        break;
    }
    setErrors(validationErrors);
  };

  useEffect(() => {
    document.title = "CookandChef Admin | Add Testimonials";
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate("");

  const submitHandler = (e) => {
    const validationErrors = {};
    e.preventDefault();
    setisDisabled(true);
    setTimeout(() => {
      setisDisabled(false);
    }, 3000);
    if (!name.trim()) {
      validationErrors.name = "Please enter name";
    } else if (!isValidName(name)) {
      validationErrors.name = "Please enter a valid name";
    }
    if (!message) {
      validationErrors.message = "Please enter message";
    } else if (!isValidName(message)) {
      validationErrors.message = "Please enter valid message";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      let status = 1;
      let item = {
        name,
        message,
        image,
      };

      dispatch(addTestimonial(item))
        .unwrap()
        .then(() => {
          navigate("/testimonial");
        })
        .catch(({ message }) => {
          alert(message);
        });
    }
  };

  const cancelHandler = (e) => {
    e.preventDefault();
    navigate(-1);
  };
  let user = JSON.parse(localStorage.getItem("users"));
  const token = user.data.token;

  async function imageUpload(e) {
    setImageError("")
    e.preventDefault();
    if (e.target.files[0].size > 2 * 1024 * 1024) {
      setImageError("Please upload an image less than 2 MB");
    } 
    
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    var requestOptions = {
      method: "POST",
      body: formData,
      redirect: "follow",
      headers: { "x-access-token": token },
    };

    let response = await fetch(API_PATHS.uploadFile, requestOptions);
    let data = await response.json();
    setImage(data?.data?.location);
    // setMessage(data?.message);
  }

  return (
    <>
      <Navbartop />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div
                    className="text-start my-2 ms-1"
                    style={{
                      fontSize: "2.2dvh",
                      fontWeight: "600",
                      color: "#ff5c09",
                    }}
                  >
                    <Link to="/home">Home</Link> &#8811;&nbsp;
                    <Link to="/testimonial">Testimonial</Link> &#8811; Add
                    Testimonial
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Add Testinomial</h4>
                      </div>
                    </div>
                    <section className="form-section">
                      <Form className="mx-4" onSubmit={submitHandler}>
                        <Row className="my-4  justify-content-start">
                          <Row className="d-flex ">
                            <Col lg={5} className="p-1">
                              <Form.Group>
                                <Form.Label>Name *</Form.Label>

                                <Form.Control
                                  name="name"
                                  value={name}
                                  onChange={handleChange}
                                  isInvalid={!!errors.name}
                                  isValid={!errors.name && name.length > 0}
                                  type="text"
                                  placeholder="Enter user's name"
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.name}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <Col lg={5} className="p-1">
                              <Form.Group>
                                <label className="mb-2 label">Image</label>

                                <Form.Control
                                  type="file"
                                  name="resume"
                                  onChange={(e) => imageUpload(e)}
                                  className="input1"
                                  //placeholder="Enter current city"
                                />
                              </Form.Group>
                              <p className="alert-message">{imageError}</p>
                            </Col>
                          </Row>
                          <Col lg={10} className="p-1">
                            <Form.Group>
                              <Form.Label>Message *</Form.Label>

                              <Form.Control
                                name="message"
                                value={message}
                                onChange={handleChange}
                                isInvalid={!!errors.message}
                                isValid={!errors.message && message.length > 0}
                                as="textarea"
                                style={{ resize: "none" }}
                                maxlength={180}
                                placeholder="Enter message"
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.message}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>

                          <Row className="justify-content-center mt-5">
                            <Col xs={6} sm={4} md={4} lg={2} xl={2} xxl={2}>
                              <button
                                className="btn cancelBtn"
                                onClick={cancelHandler}
                              >
                                Cancel
                              </button>
                            </Col>{" "}
                            <Col xs={6} sm={4} md={4} lg={2} xl={2} xxl={2}>
                              <button
                                className="btn saveBtn mx-2"
                                disabled={isdisabled}
                                type="submit"
                              >
                                Save
                              </button>
                            </Col>
                          </Row>
                        </Row>
                      </Form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddTestimonial;
