import React, { useState, useRef, useEffect } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import Navbartop from "./navbar";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import Table from "react-bootstrap/Table";
import Moment from "moment";
import { Link } from "react-router-dom";
import { MdBlock } from "react-icons/md";
import ShowSkeleton from "../utils/helpers/Skeleton";

import {
  deleteSharedProfiles,
  getEventSharedProfiles,
} from "../store/slices/events";

function SharedEventsProfilesList() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [totaldata, settotalData] = useState();
  const dispatch = useDispatch();
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [isLoading, setisLoading] = useState(true);
  const [item, setItem] = useState({
    limit: 25,
    id: id,
    page: 1,
  });


  useEffect(() => {
    document.title = "CookandChef Admin | Share Profile";
  }, []);

  const itemsPerPage = 25;
  const pagesToShowInitially = 2;
  const handlePageClick = async ({ selected }) => {
    const newPage = selected + 1;

    if (newPage === currentPage) {
      return;
    }
    const updatedItem = {
      ...item,
      limit: 25,
      page: newPage,
      id: id,
    };
    setItem(updatedItem);
    setCurrentPage(newPage);
    search.set("page", newPage);
    setSearch(search, {
      replace: true,
    });
    await getprofiles(updatedItem);
  };

  React.useEffect(() => {
    getprofiles(item);
  }, [dispatch]);

  const getprofiles = () => {
    dispatch(getEventSharedProfiles(item))
      .unwrap()
      .then((data) => {
        setData(data.profiles.data);
        settotalData(data.profiles.dataCount);
        setisLoading(true);
        setTimeout(() => {
          setisLoading(false);
        }, [1000]);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const DeleteShareProfile = (id) => {
    dispatch(deleteSharedProfiles(id))
      .unwrap()
      .then((data) => {
        alert(data?.profiles?.message);
        getprofiles();
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  let userdata = JSON.parse(localStorage.getItem("users"));
  let role = userdata.data.roleId.roleName;

  return (
    <>
      <Navbartop />

      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div
                    className="text-start my-2 ms-1"
                    style={{
                      fontSize: "2.2dvh",
                      fontWeight: "600",
                      color: "#ff5c09",
                    }}
                  >
                    <Link to="/home">Home</Link> &#8811; Shared Profiles
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Shared Profiles</h4>
                      </div>
                    </div>

                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        {isLoading ? (
                          <Col lg={12} xs={12} md={12} align="center">
                            {ShowSkeleton()}
                          </Col>
                        ) : data?.length === 0 ? (
                          <Col lg={12} md={12} sm={12} xs={12}>
                            <Row
                              className="d-flex justify-items-center align-items-center"
                              style={{
                                backgroundColor: "white",
                                // height: "20dvh",
                                // width: "100%",
                                borderRadius: "15px",
                                padding: "20px",
                              }}
                            >
                              <Col lg={12} align="center">
                                <p style={{ fontSize: "18px" }}>
                                  <b>No Shared Profiles</b> found!
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        ) : (
                          <Table responsive hover>
                            <thead>
                              <tr>
                                <th className="text-left">Name</th>
                                <th className="text-left">Mobile</th>
                                <th className="text-left">Email</th>
                                <th className="text-center">Date of Regd</th>
                                <th className="text-center"> Last Login</th>
                                <th className="text-center">Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.map((client) => (
                                <tr key={client?.cookId?.id}>
                                  <td className="text-left">
                                    <Link
                                      to={`/clientdetails/${client?.cookId?.id}`}
                                      style={{
                                        color: "#a020f0",
                                      }}
                                    >
                                      {client?.status === 3 && (
                                        <MdBlock
                                          style={{
                                            color: "red",
                                            marginRight: "2px",
                                          }}
                                        />
                                      )}
                                      {client?.cookId?.fullName?.substring(
                                        0,
                                        25
                                      )}
                                    </Link>
                                  </td>
                                  <td className="text-left">
                                    {client?.cookId?.mobile}
                                  </td>

                                  <td className="text-left">
                                    {client?.cookId?.email?.substring(0, 20)}
                                  </td>
                                  <td className="text-center">
                                    {Moment.utc(
                                      client?.cookId?.createdAt
                                    ).format("DD-MM-YYYY")}
                                  </td>
                                  <td className="text-center">
                                    {Moment.utc(
                                      client?.cookId?.lastLoginDateTime
                                    ).format("DD-MM-YYYY")}
                                  </td>

                                  <td className="text-center">
                                    <Button
                                      className="apply_btn ms-2"
                                      onClick={() =>
                                        DeleteShareProfile(client?._id)
                                      }
                                    >
                                      Delete
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                      </div>
                      <div className="justify-content-right align-right">
                        {totaldata / itemsPerPage > 1 && (
                          <ReactPaginate
                            key={currentPage}
                            previousLabel="<"
                            nextLabel=">"
                            breakLabel="..."
                            breakLinkClassName={"page-link"}
                            pageCount={totaldata / itemsPerPage}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={pagesToShowInitially}
                            onPageChange={handlePageClick}
                            containerClassName="pagination"
                            activeClassName="active"
                            pageLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            disabledClassName="disabled"
                            initialPage={currentPage - 1}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SharedEventsProfilesList;
