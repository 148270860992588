import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Navbartop from "./navbar";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Moment from "moment";

import { BiDotsVerticalRounded } from "react-icons/bi";
import Dropdown from "react-bootstrap/Dropdown";
import empimg from "../../src/Assets/Images/client.png";
import ShowprofileSkeleton from "./ProfileSkeleton";
import DataSkeleton from "./dataskeleton";
import {
  SendEventsNotifications,
  getEventDetails,
  getEventSharedProfiles,
} from "../store/slices/events";

function EventDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [sharedCount, setSharedCount] = useState();
  const [isLoading, setIsloading] = useState(true);
  localStorage.setItem("eventId", id);
  let userdata = JSON.parse(localStorage.getItem("users"));
  let role = userdata.data.roleId.roleName;

  React.useEffect(() => {
    dispatch(getEventDetails(id))
      .unwrap()
      .then((data) => {
        setData(data.events.data[0]);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  React.useEffect(() => {
    const item = {
      id: id,
    };
    dispatch(getEventSharedProfiles(item))
      .unwrap()
      .then((data) => {
        setSharedCount(data.profiles.dataCount);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);


  useEffect(() => {
    document.title = "CookandChef Admin | Event Details";
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsloading(false);
    }, 1000);
  }, []);

  const sendNotification = () => {
    dispatch(SendEventsNotifications(id))
      .unwrap()
      .then((data) => {
        alert(data?.events?.message);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const ShareEvent = () => {
    navigate(`/share-cook-profile/${id}`);
    localStorage.setItem("EventId", data?.clientId?.id);
  };

  return (
    <>
      <div>
        <Navbartop />

        <div className="container-fluid pb-5 response-cover">
          <div className="row">
            <div className="col-lg-2 col-md-4" />
            <div className="col-lg-10 col-md-8">
              <div className="container-fluid pt-5">
                <div className="row">
                  <div className="col-lg-10 col-md-12 col-12">
                    <div
                      className="text-start my-2 ms-1"
                      style={{
                        fontSize: "2.2dvh",
                        fontWeight: "600",
                        color: "#ff5c09",
                      }}
                    >
                      <Link to="/home">Home</Link> &#8811;{" "}
                      <Link to="/events">Events</Link> &#8811; Event Details
                    </div>

                    <div className="card">
                      <div className="card-header">
                        {isLoading ? (
                          <Row>{ShowprofileSkeleton()}</Row>
                        ) : (
                          <Row>
                            <Col xs={4} md={2} lg={2} xl={2}>
                              {data?.clientId?.dp ? (
                                <img
                                  src={data?.clientId?.dp}
                                  alt=""
                                  style={{
                                    height: "70px",
                                    width: "70px",
                                    borderRadius: "20%",
                                  }}
                                  className="mt-2"
                                />
                              ) : (
                                <img
                                  src={empimg}
                                  alt=""
                                  style={{
                                    height: "80px",
                                    borderRadius: "20%",
                                  }}
                                  className=" "
                                />
                              )}
                            </Col>
                            <Col xs={6} md={8} lg={9} align="left">
                              <h5>{data?.clientId?.fullName}</h5>
                              <p>{data?.clientId?.mobile}</p>
                              {data?.totalInterestsReceived !== 0 && (
                                <Link to={`/events/applications/${data?.id}`}>
                                  {data?.totalInterestsReceived} Requests
                                </Link>
                              )}
                            </Col>

                            {data?.status === 1 && (
                              <Col xs={1} md={1} lg={1} align="right">
                                {(role === "admin" ||
                                  role === "Manager" ||
                                  role === "Executive") && (
                                  <Dropdown
                                    style={{
                                      backgroundColor: "none",
                                    }}
                                  >
                                    <Dropdown.Toggle
                                      // id="dropdown-basic"
                                      style={{
                                        fontSize: "20px",
                                        backgroundColor: "none",
                                        border: "none",
                                      }}
                                    >
                                      <BiDotsVerticalRounded
                                        style={{
                                          marginLeft: "-5px",
                                          marginTop: "-2px",
                                          color: "#000",
                                        }}
                                      />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        href="#/action-1"
                                        onClick={sendNotification}
                                      >
                                        Send Notification
                                      </Dropdown.Item>
                                      <Dropdown.Item onClick={ShareEvent}>
                                        Share Cook Profiles
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                )}
                              </Col>
                            )}
                            <div style={{ textAlign: "right" }}>
                              {role !== "Associate" && (
                                <Link
                                  style={{ color: "#a020f0" }}
                                  target="_blank"
                                  to={`/shared-event-profiles-list/${id}`}
                                >
                                  {sharedCount >= 1
                                    ? `View shared profiles`
                                    : ""}
                                </Link>
                              )}
                            </div>
                          </Row>
                        )}

                        <hr />
                        {isLoading ? (
                          <Row>{DataSkeleton()}</Row>
                        ) : (
                          <Row className="card pb-3">
                            <Row>
                              {data?.status === 1 && (
                                <div align="right">
                                  {(role === "admin" || role === "Manager") && (
                                    <Link
                                      to={`/editevent-details/${id}`}
                                      style={{
                                        color: "#ff5c09",
                                        fontWeight: "600",
                                        borderBottom: "1px solid #ff5c09",
                                      }}
                                    >
                                      Edit
                                    </Link>
                                  )}
                                </div>
                              )}
                              <h5
                                style={{ fontWeight: "500", color: "#ff5c09" }}
                              >
                                {" "}
                                Event Details
                              </h5>
                              <Col xs={12} md={12} lg={6}>
                                <p className="text-heading">Event Date</p>
                                {data?.eventDate && (
                                  <p className="text-details">
                                    {Moment.utc(data?.eventDate).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </p>
                                )}
                              </Col>
                              <Col xs={12} md={12} lg={6}>
                                <p className="text-heading">Event Type</p>
                                {data.eventType && (
                                  <p className="text-details">
                                    {data.eventType}
                                  </p>
                                )}
                              </Col>

                              <Col xs={12} md={12} lg={6}>
                                <p className="text-heading">Expected Guests</p>
                                {data.expectedGuest && (
                                  <p className="text-details">
                                    {data.expectedGuest}
                                  </p>
                                )}
                              </Col>
                              <Col xs={12} md={12} lg={6}>
                                <p className="text-heading">Dishes</p>
                                {data.dishes && (
                                  <p className="text-details">{data.dishes}</p>
                                )}
                              </Col>
                              <Col xs={12} md={12} lg={6}>
                                <p className="text-heading">Cuisine</p>
                                <Row
                                  style={{
                                    marginTop: "-15px",
                                    marginLeft: "0px",
                                  }}
                                >
                                  {data?.cuisines?.map(
                                    (chefCusineVal, index) => (
                                      <Col
                                        xs={5}
                                        sm={5}
                                        md={5}
                                        lg={5}
                                        xl={5}
                                        className="me-2"
                                        style={{
                                          backgroundColor: "#ffffff",
                                          borderRadius: "10px",
                                          color: "black",
                                          padding: "4px 8px",
                                          margin: "8px 0px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          textAlign: "center",
                                          fontSize: "16px",
                                          marginRight: "4px",
                                          border: "solid 1px #ff5c09",
                                          width: "auto",
                                        }}
                                      >
                                        {chefCusineVal}
                                      </Col>
                                    )
                                  )}
                                </Row>
                              </Col>
                              <Col xs={12} md={12} lg={6}>
                                <p className="text-heading">Event Location</p>
                                {data.location && (
                                  <p className="text-details">
                                    {data.location}
                                  </p>
                                )}
                              </Col>
                              <Col xs={12} md={12} lg={6}>
                                <p className="text-heading">Event City</p>
                                {data.city && (
                                  <p className="text-details">{data.city}</p>
                                )}
                              </Col>
                              <Col xs={12} md={12} lg={6}>
                                <p className="text-heading">Pincode</p>
                                {data.pincode && (
                                  <p className="text-details">{data.pincode}</p>
                                )}
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <h5
                                style={{ fontWeight: "500", color: "#ff5c09" }}
                              >
                                Client Details
                              </h5>
                              <Col xs={12} md={12} lg={6}>
                                <p className="text-heading">Name</p>
                                {data?.clientId?.fullName && (
                                  <p className="text-details">
                                    {data.clientId.fullName}
                                  </p>
                                )}
                              </Col>
                              <Col xs={12} md={12} lg={6}>
                                <p className="text-heading">Mobile Number</p>
                                {data?.clientId?.mobile && (
                                  <p className="text-details">
                                    {data.clientId.mobile}
                                  </p>
                                )}
                              </Col>
                              <Col xs={12} md={12} lg={6}>
                                <p className="text-heading">Email</p>
                                {data?.clientId?.email && (
                                  <p className="text-details">
                                    {data.clientId.email}
                                  </p>
                                )}
                              </Col>

                              {/* <Col xs={12} md={12} lg={6}>
                              <p className="text-heading">Property Type</p>
                              {data?.employerId?.propertyType === 1 && (
                                <p className="text-details">Hotel</p>
                              )}
                              {data?.employerId?.propertyType === 2 && (
                                <p className="text-details">Restaurant</p>
                              )}
                              {data?.employerId?.propertyType === 3 && (
                                <p className="text-details">Cafe</p>
                              )}
                              {data?.employerId?.propertyType === 4 && (
                                <p className="text-details">Cloud Kitchen</p>
                              )}
                              {data?.employerId?.propertyType === 5 && (
                                <p className="text-details">Other</p>
                              )}
                            </Col> */}
                            </Row>
                          </Row>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EventDetails;
