import React, { useState, useRef, useEffect } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import Navbartop from "./navbar";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { BsGlobe2 } from "react-icons/bs";
import Moment from "moment";
import { API_PATHS } from "../utils/constants/api.constants";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { MdBlock } from "react-icons/md";
import ExportCSVButton from "../utils/helpers/ExportButtons";
import ShowSkeleton from "../utils/helpers/Skeleton";
import { getAllCookList } from "../store/slices/cook";

function AllCookList() {
  const [data, setData] = useState([]);
  const [totaldata, settotalData] = useState();
  const [coordinates, setCoordinates] = useState([]);
  const [location, setLocation] = useState("");
  const [nameEmailOrMobile, setnameEmailOrMobile] = useState("");
  const dispatch = useDispatch();
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const [isLoading, setisLoading] = useState(true);
  const [limit, setLimit] = useState();
  const [locationError, setLocationError] = useState("");
  const [item, setItem] = useState({
    limit: 25,
    page: 1,
  });

  // react paginate

  const itemsPerPage = 25;
  const pagesToShowInitially = 2;

  useEffect(() => {
    document.title = "CookandChef Admin | Cooks";
  }, []);

  const handleApplyFilter = () => {
    const item = {
      latitude: coordinates[0] || undefined,
      longitude: coordinates[1] || undefined,
      nameEmailOrMobile: nameEmailOrMobile || undefined,
      limit: 25,
      page: 1,
    };
    search.set("page", 1);
    setSearch(search, {
      replace: true,
    });
    if (locationError) {
    } else {
      setItem(item);
      setCurrentPage(1);
      getcookslist(item);
    }
  };

  useEffect(() => {
    if (location === "") {
      setCoordinates([]);
    }
  }, [location]);
  const handlePageClick = async ({ selected }) => {
    const newPage = selected + 1;

    if (newPage === currentPage) {
      return;
    }
    const updatedItem = {
      ...item,
      limit: 25,
      page: newPage,
    };
    setItem(updatedItem);
    setCurrentPage(newPage);
    search.set("page", newPage);
    setSearch(search, {
      replace: true,
    });
    await getcookslist(updatedItem);
  };

  const handlenameChange = (e) => {
    setnameEmailOrMobile(e.target.value);
    search.set("nameEmailOrMobile", e.target.value);
    setSearch(search, {
      replace: true,
    });
  };

  const inputRef = useRef(null);

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      const googleMapsScript = document.createElement("script");
      googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${API_PATHS.googlemapsapikey}&libraries=places`;
      googleMapsScript.onload = initializeAutocomplete;
      googleMapsScript.onerror = () => {
        console.error("Failed to load Google Maps API script");
      };
      document.body.appendChild(googleMapsScript);
    };

    const initializeAutocomplete = () => {
    if (inputRef.current) {
      const autoCompleteCity = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          componentRestrictions: { country: "in" },
        }
      );

      autoCompleteCity.addListener("place_changed", () => {
        const place = autoCompleteCity.getPlace();
        if (!place.geometry || !place.geometry.location) {
          setLocationError(
            "Please select location from the suggestion drop-down"
          );
        } else {
          setLocationError("");
          setLocation(place.name);
          const coordinates = [
            place.geometry.location.lat(),
            place.geometry.location.lng(),
          ];
          setCoordinates(coordinates);
          localStorage.setItem("coordinates", coordinates);
         
        }
      });
      inputRef.current.addEventListener("blur", () => {
        const place = autoCompleteCity.getPlace();
        const inputValue = inputRef.current.value.trim();
        if (inputValue && (!place?.geometry || !place?.geometry?.location)) {
          setLocationError(
            "Please select location from the suggestion drop-down"
          );
        }
      });
    }
  };
  loadGoogleMapsScript();


  return () => {
    // Clean up code here, if needed
  };
}, []); 


useEffect(()=>{
  if(location !== ""){
  search.set("location", location);
  setSearch(search, {
    replace: true,
  })
}
},[location])

  useEffect(() => {
    const locationq = search.get("location");
    const keywordq = search.get("nameEmailOrMobile");
    const pageq = search.get("page");

    if (keywordq) {
      setnameEmailOrMobile(keywordq);
    }
    let coordString;
    let coordArray;
    if (locationq !== null) {
      setLocation(locationq);
      coordString = localStorage.getItem("coordinates");
      coordArray = coordString ? coordString.split(",") : [];
      setCoordinates(coordArray);
    }
    if (locationq || keywordq || pageq) {
      const item = {
        latitude: coordArray ? coordArray[0] : undefined,
        longitude: coordArray ? coordArray[1] : undefined,
        nameEmailOrMobile: keywordq || undefined,
        limit: 25,
        page: parseInt(pageq) || 1,
      };

      setItem(item);
      getcookslist(item);
    } else {
      getcookslist(item);
    }
  }, []);

  const handlelocationChange = (e) => {
    setLocation(e.target.value);
    setLocationError("");
  };

  const getcookslist = (item) => {
    dispatch(getAllCookList(item))
      .unwrap()
      .then((data) => {
        setData(data.users.data);
        settotalData(data.users.totalDataCount);

        setLimit(data.users.totalDataCount);
        setisLoading(true);
        setTimeout(() => {
          setisLoading(false);
        }, [1000]);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const handleResetFilter = (e) => {
    e.preventDefault();
    setLocation("");
    setCoordinates([]);
    setnameEmailOrMobile("");
    const item = {
      limit: 25,
      page: 1,
    };
    setItem(item);
    setSearch([]);
    setCurrentPage(1);
    getcookslist(item);
  };

  let userdata = JSON.parse(localStorage.getItem("users"));
  let role = userdata.data.roleId.roleName;

  return (
    <>
      <Navbartop />
      <div className="container-fluid pb-5 response-cover">
        <div className="row">
          <div className="col-lg-2 col-md-4" />
          <div className="col-lg-10 col-md-8">
            <div className="container-fluid pt-5">
              <div className="row">
                <div className="col-12">
                  <div
                    className="text-start my-2 ms-1"
                    style={{
                      fontSize: "2.2dvh",
                      fontWeight: "600",
                      color: "#ff5c09",
                    }}
                  >
                    <Link to="/home">Home</Link> &#8811; Cooks
                  </div>
                  <div className="card">
                    <div className="card-header d-flex justify-content-between">
                      <div className="card-title-wrap bar-success d-flex align-items-center">
                        <h4 className="card-title">Cooks</h4>
                      </div>

                      {role === "admin" && (
                        <div align="right">
                          <div>
                            <ExportCSVButton
                              limit={limit}
                              filename="cooks.xlsx"
                              getData={getAllCookList}
                              item={item}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="cards ps-1 pe-1">
                      <Form>
                        <Row className="d-flex flex-row card ps-2 pe-2">
                          <Col
                            lg={3}
                            md={6}
                            xs={12}
                            className="d-flex flex-column mb-2"
                          >
                            <Form.Group>
                              <Form.Control
                                name="location"
                                placeholder="Enter location"
                                value={location}
                                ref={inputRef}
                                onChange={handlelocationChange}
                                isInvalid={!!locationError}
                              />
                              <Form.Control.Feedback type="invalid">
                                {locationError}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>

                          <Col
                            lg={3}
                            md={6}
                            xs={12}
                            className="d-flex flex-column mb-2"
                          >
                            <Form.Group>
                              <Form.Control
                                name="nameEmailOrMobile"
                                placeholder="Name/ Email/ Mobile"
                                value={nameEmailOrMobile}
                                onChange={handlenameChange}
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <div className="group d-flex justify-content-center mt-1 ">
                          <Button
                            className="cancel_btn"
                            onClick={handleResetFilter}
                          >
                            Reset
                          </Button>
                          <Button
                            className="apply_btn ms-2"
                            onClick={handleApplyFilter}
                          >
                            Search
                          </Button>
                        </div>
                      </Form>
                    </div>
                    <div className="card-body collapse show">
                      <div className="card-block card-dashboard table-responsive">
                        {isLoading ? (
                          <Col lg={12} xs={12} md={12} align="center">
                            {ShowSkeleton()}
                          </Col>
                        ) : data?.length === 0 ? (
                          <Col lg={12} md={12} sm={12} xs={12}>
                            <Row
                              className="d-flex justify-items-center align-items-center"
                              style={{
                                backgroundColor: "white",
                                // height: "20dvh",
                                // width: "100%",
                                borderRadius: "15px",
                                padding: "20px",
                              }}
                            >
                              <Col lg={12} align="center">
                                <p style={{ fontSize: "18px" }}>
                                  <b>No results</b> found for your search!
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        ) : (
                          <Table responsive hover>
                            <thead>
                              <tr>
                                <th
                                  className="text-left"
                                  style={{ width: "20%" }}
                                >
                                  Name
                                </th>
                                <th className="text-left">Mobile</th>
                                <th className="text-left">Email</th>
                                <th className="text-center">Date of Regd</th>
                                <th className="text-center"> Last Login</th>
                                <th className="text-center">Cook Type</th>
                                <th className="text-left">Web/ App</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.map((cook) => (
                                <tr key={cook?._id}>
                                  <td className="text-left">
                                    <Link
                                      to={`/cookdetails/${cook?._id}`}
                                      style={{
                                        color: "#a020f0",
                                      }}
                                    >
                                      {cook?.status === 3 && (
                                        <MdBlock
                                          className="me-2"
                                          style={{ color: "red" }}
                                        />
                                      )}
                                      {cook?.fullName?.substring(0, 25)}
                                    </Link>
                                  </td>
                                  <td className="text-left">{cook?.mobile}</td>

                                  <td className="text-left">
                                    {cook?.email?.substring(0, 20)}
                                  </td>

                                  <td className="text-center">
                                    {Moment.utc(cook?.createdAt).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {Moment.utc(cook?.lastLoginDateTime).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </td>

                                  <td className="text-left">
                                    {cook?.cookType === 1 && (
                                      <span
                                        style={{
                                          background: "#fef5e5",
                                          color: "#ffb435",
                                          padding: "3px",
                                          borderRadius: "3px",
                                        }}
                                      >
                                        Household
                                      </span>
                                    )}
                                    &nbsp;
                                    {cook?.cookType === 2 && (
                                      <span
                                        style={{
                                          background: "#ecf2ff",
                                          color: "#5d87ff",
                                          padding: "3px",
                                          borderRadius: "3px",
                                        }}
                                      >
                                        Chef
                                      </span>
                                    )}
                                    &nbsp;
                                    {cook?.partyCook === 1 && (
                                      <span
                                        style={{
                                          background: "#e8f7ff",
                                          color: "#4bbeff",
                                          padding: "3px",
                                          borderRadius: "3px",
                                        }}
                                      >
                                        Party
                                      </span>
                                    )}
                                    {cook?.memberType === 2 && (
                                      <span
                                        style={{
                                          background: "#feeee8",
                                          color: "#fa896b",
                                          padding: "3px",
                                          borderRadius: "3px",
                                        }}
                                      >
                                        Catering
                                      </span>
                                    )}
                                  </td>

                                  <td className="text-left">
                                    {cook?.webAccess === 0 && (
                                      <BsGlobe2
                                        style={{
                                          color: "black",
                                          fontSize: "18px",
                                        }}
                                        className="m-1"
                                      />
                                    )}
                                    {cook?.webAccess === 1 && (
                                      <BsGlobe2
                                        style={{
                                          color: "orange",
                                          fontSize: "18px",
                                        }}
                                        className="m-1"
                                      />
                                    )}
                                    {!(
                                      cook?.webAccess === 0 ||
                                      cook?.webAccess === 1
                                    ) && (
                                      <BsGlobe2
                                        style={{
                                          color: "black",
                                          fontSize: "18px",
                                        }}
                                        className="m-1"
                                      />
                                    )}
                                    {!(
                                      cook?.appAccess === 0 ||
                                      cook?.appAccess === 1
                                    ) && (
                                      <HiOutlineDevicePhoneMobile
                                        style={{
                                          color: "black",
                                          fontSize: "18px",
                                        }}
                                        className="m-1"
                                      />
                                    )}
                                    {cook?.appAccess === 0 && (
                                      <HiOutlineDevicePhoneMobile
                                        style={{
                                          color: "black",
                                          fontSize: "18px",
                                        }}
                                        className="m-1"
                                      />
                                    )}
                                    {cook?.appAccess === 1 && (
                                      <HiOutlineDevicePhoneMobile
                                        style={{
                                          color: "blue",
                                          fontSize: "18px",
                                        }}
                                        className="m-1"
                                      />
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                      </div>
                      <div className="justify-content-right">
                        {totaldata / itemsPerPage > 1 && (
                          <ReactPaginate
                            key={currentPage}
                            previousLabel="<"
                            nextLabel=">"
                            breakLabel="..."
                            breakLinkClassName={"page-link"}
                            pageCount={totaldata / itemsPerPage}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={pagesToShowInitially}
                            onPageChange={handlePageClick}
                            containerClassName="pagination"
                            activeClassName="active"
                            pageLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            disabledClassName="disabled"
                            initialPage={currentPage - 1}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllCookList;
